import { client } from './axios'

export const getFestivals = async ({ page, size, title, festivalType }) => {
  const res = await client.get(`/festival/festival/`, { params: { page, size, title, festivalType } })
  return res.data
}

export const getFestival = async ({ id }) => {
  const res = await client.get(`/festival/festival/${id}/`)
  return res.data
}

export const getEvents = async ({ page, size }) => {
  const res = await client.get(`/common/event/`, { params: { page, size } })
  return res.data
}

export const postServiceCenter = async ({ type, phone, email, content }) => {
  const res = await client.post(`/common/inquiry/`, { type, phone, email, content })
  return res.data
}

export const getGoogleUserInfo = async ({ access_token }) => {
  const response = await fetch(`https://www.googleapis.com/oauth2/v3/userinfo?access_token=${access_token}`)
  console.log('response', response)
  const body = await response.json();
  console.log('body', body);
  return body
}
