import Router from '@/Router'

import {Provider} from 'react-redux';
import rootReducer from './redux/reducers';
import {configureStore} from '@reduxjs/toolkit';
import RightModal from '@/components/modals/RightModal'
import AlertModal from '@/components/modals/AlertModal'
import { persistStore, persistReducer } from 'redux-persist'
import {PersistGate} from 'redux-persist/integration/react'
import storage from 'redux-persist/lib/storage'
import ShareModal from '@/components/modals/ShareModal'

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistStore(store)}>
        <Router />
        <RightModal />
        <AlertModal />
        <ShareModal />
      </PersistGate>
    </Provider>
  )
}

export default App
