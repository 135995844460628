import styles from './InputLabel.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

/**
 *
 * @param {Object} props
 * @param {string} [props.className]
 * @returns {JSX.Element}
 */
const InputLabel = (props) => {
  const { required = false, children, htmlFor, className: rootClassName } = props
  const className = cx(styles.root, rootClassName, { required: required })

  return (
    <>
      <label htmlFor={htmlFor} className={className}>
        {children}
      </label>
    </>
  )
}

export default InputLabel
