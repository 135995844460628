import styles from './Icon.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

/**
 *
 * @param {Object} props
 * @param {string} [props.className]
 * @returns {JSX.Element}
 */
const Icon = (props) => {
  const {
    width = '',
    height = '',
    variant = 'icon_down_arrow',
    level = '',
    disabled = false,
    className: rootClassName,
    style
  } = props

  const className = cx(styles.root, rootClassName, styles[variant], styles[level], { disabled })

  return (
    <>
      <div className={className} style={{ width, height }} style={style}></div>
    </>
  )
}

export default Icon
