import React from 'react';
import {bindActionCreators} from 'redux';
import actions from '../../redux/actions';
import {connect} from 'react-redux';
import Modal from 'react-modal'
import classNames from 'classnames/bind'
import styles from '@/components/modals/AlertModal.module.scss'
import strings from '@/lang/strings'

const cx = classNames.bind(styles)

const AlertModal = ({appState: {alertModal}, appActions}) => {
  const className = cx(styles.root)

  const hide = onPress => {
    appActions.hideAlertModal();

    if (typeof onPress === 'function') {
      setTimeout(() => onPress(), 300);
    }
  };

  return (
    <Modal
      isOpen={alertModal.isVisible}
      key={'alertModal'}
      onRequestClose={alertModal.isCancelable ? hide : () => {
      }}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          zIndex: "10000"
        },
        content: {
          border: "none",
          top: "50%",
          left: "50%",
          backgroundColor: "white",
          borderRadius: "20px",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          minHeight: "147px",
          width: "calc(100% - 115px)",
          maxWidth: "767px",
          padding: "40px 24px 30px 24px"
        }
      }}
      shouldCloseOnOverlayClick={true} >
      <div className={className}>
        <div className={styles.text}>{alertModal.text}</div>
        <div className={cx(styles.button_group, "mt-17")}>
          <div
            onClick={() => {
              alertModal.onPressLeft && alertModal.onPressLeft();
              hide();
            }}
            className={cx(styles.button)}>
              {alertModal.leftBtnText ? alertModal.leftBtnText : strings.confirm}
          </div>
          {
            alertModal.rightBtnText &&
            <div
              onClick={() => {
                alertModal.onPressRight && alertModal.onPressRight();
                hide();
              }}
              className={cx(styles.button, "orange")}>
              {alertModal.rightBtnText ? alertModal.rightBtnText : strings.cancel}
            </div>
          }
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = state => ({
  appState: state.appState,
});

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators(actions.appActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertModal);
