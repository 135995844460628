import {handleActions} from 'redux-actions';

const initialState = {
  musics: [],
  cultures: [],
  foods: [],
  concerts: [],
  etcs: [],
  festivalType: '',
  address: '전체',
  ordering: '',

  mdFestivals: [],
  recommendFestivals: [],
  festival: {},
  tourOrStore: {},
  person: {},
  timeTable: {},
};

export default handleActions(
  {
    'FESTIVAL/SET_MUSICS': (state, action) =>
      Object.assign({}, state, {
        musics: action.payload,
      }),
    'FESTIVAL/SET_CULTURES': (state, action) =>
      Object.assign({}, state, {
        cultures: action.payload,
      }),
    'FESTIVAL/SET_FOODS': (state, action) =>
      Object.assign({}, state, {
        foods: action.payload,
      }),
    'FESTIVAL/SET_CONCERTS': (state, action) =>
      Object.assign({}, state, {
        concerts: action.payload,
      }),
    'FESTIVAL/SET_ETCS': (state, action) =>
      Object.assign({}, state, {
        etcs: action.payload,
      }),
    'FESTIVAL/SET_FESTIVAL_TYPE': (state, action) =>
      Object.assign({}, state, {
        festivalType: action.payload,
      }),
    'FESTIVAL/SET_ADDRESS': (state, action) =>
      Object.assign({}, state, {
        address: action.payload,
      }),
    'FESTIVAL/SET_ORDERING': (state, action) =>
      Object.assign({}, state, {
        ordering: action.payload,
      }),

    'FESTIVAL/SET_MD_FESTIVALS': (state, action) =>
      Object.assign({}, state, {
        mdFestivals: action.payload,
      }),
    'FESTIVAL/SET_RECOMMEND_FESTIVALS': (state, action) =>
      Object.assign({}, state, {
        recommendFestivals: action.payload,
      }),
    'FESTIVAL/SET_FESTIVAL': (state, action) =>
      Object.assign({}, state, {
        festival: action.payload,
      }),
    'FESTIVAL/SET_TOUR_OR_STORE': (state, action) =>
      Object.assign({}, state, {
        tourOrStore: action.payload,
      }),
    'FESTIVAL/SET_PERSON': (state, action) =>
      Object.assign({}, state, {
        person: action.payload,
      }),
    'FESTIVAL/SET_TIME_TABLE': (state, action) =>
      Object.assign({}, state, {
        timeTable: action.payload,
      }),
  },
  initialState,
);
