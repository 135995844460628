import {combineReducers} from 'redux';

import appState from './appState';
import user from './user';
import festival from "./festival";
import classes from './classes'
import event from "./event";

export default combineReducers({
  appState,
  user,
  festival,
  classes,
  event
});
