import styles from './Footer.module.scss'
import classNames from 'classnames/bind'
import React, {useEffect} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {bindActionCreators} from 'redux';
import actions from '../../redux/actions';
import strings from '@/lang/strings'
import iconBottomBtn1 from '@/assets/images/icon_bottom_btn1@2x.png'
import iconBottomBtn2 from '@/assets/images/icon_bottom_btn2@2x.png'
import iconBottomBtn3 from '@/assets/images/icon_bottom_btn3@2x.png'

const cx = classNames.bind(styles)

const Footer = ({
  backgroundColor = '#fff'
}) => {
  const className = cx(styles.root)

  const handleFacebook = () => {
    window.open('https://www.facebook.com/festivalon.net', '_blank');
  }

  const handleYoutube = () => {
    window.open('https://www.youtube.com/@festivalon', '_blank');
  }

  const handleInstagram = () => {
    window.open('https://www.instagram.com/festivalon_net/', '_blank');
  }

  return (
    <div id={'footer'} className={cx(className, "pt-26")} style={{backgroundColor: backgroundColor}}>
      <div className={cx(styles.business_info)}>{strings.footer.business_info}</div>
      <div className={cx(styles.business_info, "mt-6")}>{strings.footer.contact_info}</div>
      <div className={"mt-10"} style={{textAlign: "center"}}>
        <img src={iconBottomBtn1} className={styles.bottom_btn} onClick={handleFacebook}/>
        <img src={iconBottomBtn2} className={cx(styles.bottom_btn, "mh-10")} onClick={handleYoutube}/>
        <img src={iconBottomBtn3} className={styles.bottom_btn} onClick={handleInstagram}/>
      </div>
      <div className={cx(styles.business_info, "mt-10 pb-38")}>{strings.footer.copyright_info}</div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.user,
  appState: state.appState,
});

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators(actions.appActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
