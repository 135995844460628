import styles from './Event.module.scss'
import BasePage from '@/pages/BasePage'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames/bind'
import Header from '@/components/header/Header'
import Icon from '@/components/Icon/Icon'
import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import actions from "../../redux/actions";
import { connect } from 'react-redux'
import Footer from '@/components/footer/Footer'
import strings from '@/lang/strings'
import { getDateString, getDistanceFromLatLonInKm, sendRequest } from '@/common/common'
import imgSub03Top from '@/assets/images/img_sub_03_top@2x.png'
import EventCell from '@/components/home/EventCell'

const cx = classNames.bind(styles)

const Event = ({appState: {spinner}, user: {userLocation}, appActions, userActions, eventActions}) => {
  const navigate = useNavigate()

  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const [cellWidth, setCellWidth] = React.useState(0);
  const [events, setEvents] = useState([])

  useEffect(() => {
    getEvents()
    updateCellWidth(screenWidth);
    const handleResizeWindow = () => updateCellWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, [])

  const getEvents = () => {
    sendRequest('common/event', 'GET', null, data => {
      setEvents(data?.filter(item => item?.type === '온라인'))
    })
  }

  const updateCellWidth = (screenWidth) => {
    screenWidth = screenWidth > 1320 ? 1320 : screenWidth
    setScreenWidth(screenWidth)
    setCellWidth((screenWidth - (8 + 12 * 2) * (screenWidth > 767 ? 1.7 : 1)) / 2)
  }

  return (
    <>
      <BasePage id={'search'} className={cx(styles.root)} pageContent={
        <>
          <Header
            styles={{position: 'absolute'}}
            hasBack
            backIcon={'icon_back_white'}
            hasBackground={false}
            rightComp={
              <div style={{display: 'flex'}}>
                <div className={"w-30 h-40 center"} onClick={() => navigate('/search')}>
                  <Icon variant="search" className={"w-16 h-16"} />
                </div>
                <div className={"w-30 h-40 center mr-5"} onClick={() => appActions.showRightModal()}>
                  <Icon variant="icon_option" className={"w-16 h-16"} />
                </div>
              </div>
            }
          />
          <div className={styles.top}>
            <img src={imgSub03Top} className={"w100"} />
            <div className={styles.contents}>
              <div className={styles.title}>
                {strings.event.event}
              </div>
              <div className={cx(styles.desc, "mt-14")}>
                {strings.event.even_desc}
              </div>
            </div>
          </div>
          <div style={{flex: 1}}>
            <div className={styles.section_header}>
              <div className={styles.section_title}>{strings.event.event}</div>
            </div>
            <div>
              <div className={cx(styles.festival_wrapper, "mt-13")}>
                {
                  events.map((item, index) => {
                    return (
                      <EventCell
                        key={index}
                        title={item.title}
                        date={getDateString(new Date(item.startDateTime)) + ' ~ ' + getDateString(new Date(item.endDateTime))}
                        location={item?.address}
                        aspectRatio={1.91}
                        distance={getDistanceFromLatLonInKm(item?.lat, item?.lon, userLocation.latitude, userLocation.longitude) + 'km'}
                        item={item}
                        showInfoOuter={true}
                        pointType={item?.pointType}
                        width={cellWidth}
                        style={{marginRight: (index % 2 !=1) && '8px'}} 
                        onClick={() => {
                          if (item.url) {
                            window.open(item.url, '_blank');
                          } else {
                            appActions?.showShareModal();
                          }
                        }}                        
                        />
                    )
                  })
                }
              </div>
            </div>
          </div>
          <div className={"mt-45"} />
          <Footer backgroundColor={"#f3f3f3"} />
        </>
      } />
    </>
  )
}

const mapStateToProps = state => ({
  appState: state.appState,
  user: state.user,
  festival: state.festival
});

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators(actions.appActions, dispatch),
  userActions: bindActionCreators(actions.userActions, dispatch),
  festivalActions: bindActionCreators(actions.festivalActions, dispatch),
  eventActions: bindActionCreators(actions.eventActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Event);
