
import KakaoLogin from "react-kakao-login";
import Button from '@/components/button/Button'
import Icon from '@/components/Icon/Icon'
import strings from '@/lang/strings'

const SocialKakao =(props)=>{
    const {
    onSuccess
  } = props

    const kakaoClientId = 'b1dde390c35802626baf05445e9e2fa1'
    const kakaoOnSuccess = async (data)=>{
      	console.log(data)
        const idToken = data.response.access_token  // 엑세스 토큰 백엔드로 전달
        const sid = data.profile.id
        console.log('sid', sid)
        const email = data.profile.kakao_account.email

      if (onSuccess) {
        onSuccess(sid, email)
      }
    }
    const kakaoOnFailure = (error) => {
        console.log(error);
    };
    return(
        <>
          <KakaoLogin
              token={kakaoClientId}
              onSuccess={kakaoOnSuccess}
              onFail={kakaoOnFailure}
              render={({ onClick }) => (
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    onClick();
                  }}
                >
                  <Button color="yellow" fullWidth={true} className={"mt-50"}>
                    <Icon variant="icon_sns3" className={"mr-7 w-22 h-23"} />
                    {strings.login.signin_with_kakao}
                  </Button>
                </div>
              )}
          />
        </>
    )
}

export default SocialKakao
