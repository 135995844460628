import styles from "./Auth.module.scss"
import BasePage from '@/pages/BasePage'
import TopBar from '@/components/topbar/TopBar'
import strings from '@/lang/strings'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames/bind'
import Button from '@/components/button/Button'
import { InputText } from '@/components/input'
import StepBar from '@/components/stepBar/StepBar'
import SelectableBadge from '@/components/button/SelectableBadge'
import { sendRequest } from '@/common/common'
import { bindActionCreators } from 'redux'
import actions from '@/redux/actions'
import { connect } from 'react-redux'

const cx = classNames.bind(styles)

const AuthGenreForm = ({user: {user}, appActions, userActions}) => {
  const navigate = useNavigate()

  const [genres, setGenres] = useState([])
  const [customGenre, setCustomGenre] = useState('')

  const handleNext = async () => {
    userActions?.setUserInfo({
      birthYear: user?.birthYear ? user?.birthYear?.replaceAll('-', '') : '1900',
      interestMusic: genres?.toString() + (isSelected(strings.auth.etc) ? ',' + customGenre : '')
    })

    console.log({
      ...user,
      birthYear: user?.birthYear ? user?.birthYear?.replaceAll('-', '') : '1900',
      // phone: getPhoneNumber(user.phone),
      interestMusic: genres?.toString() + (isSelected(strings.auth.etc) ? ',' + customGenre : ''),
      firstSetup: true,
    })

    sendRequest(`accounts/user/${user?.id}/`, 'PUT', {
      ...user,
      birthYear: user?.birthYear ? user?.birthYear?.replaceAll('-', '') : '1900',
      // phone: getPhoneNumber(user.phone),
      interestMusic: genres?.toString() + (isSelected(strings.auth.etc) ? ',' + customGenre : ''),
      firstSetup: true,
    },  data => {
      userActions?.setUserInfo({
        firstSetup: true
      })
      appActions.showAlertModal({
        text: strings.auth.signup_success,
        onPressLeft: () => navigate('/home')
      })
    })
  }

  const isSelected = (genre) => {
    const index = genres.indexOf(genre)
    if (index === -1) {
      return false
    } else {
      return true
    }
  }

  const onSelect = (genre) => {
    const newGenres = Object.assign([], genres)
    const index = genres.indexOf(genre)
    if (index === -1) {
      newGenres.push(genre)
    } else {
      newGenres.splice(index, 1)
    }
    setGenres(newGenres)
    setCustomGenre(!isSelected(strings.auth.etc) ? "" : customGenre);
  }

  const isValid = genres?.length > 1 || genres?.length > 0 && !isSelected(strings.auth.etc) || customGenre;

  return (
    <>
      <BasePage id={"auth-genre-form"} className={cx(styles.root, "h100")} pageContent={
        <>
          <TopBar title={strings.auth.custom_info_set} onBack={() => {
            navigate(-1);
          }} />
          <div className={cx(styles.container, "mh-16")}>
            <div style={{flex: 1}}>
              <StepBar seek={4} max={4} className={"mt-5"} />
              <div className={cx(styles.tip, 'fs-13 mt-30')}>{strings.auth.select_genre}</div>
              <div className={cx(styles.badge_wrapper, 'mt-25')}>
                {
                  strings.auth.genres.map((value, index) => {
                    const isSelect = isSelected(value)
                    return (
                      <SelectableBadge key={index} title={value} isSelected={isSelect} onSelect={onSelect}/>
                    )
                  })
                }
                {
                  isSelected(strings.auth.etc) &&
                  <InputText
                    type="text"
                    inputProps={{
                      placeholder: strings.auth.genre_placeholder,
                    }}
                    value={customGenre}
                    onChange={(v) => setCustomGenre(v)}
                  />
                }
              </div>
            </div>
            <Button color={isValid ? "orange" : "disable"} fullWidth={true} onClick={isValid && handleNext} className={"mt-5 mb-30"}>
              {strings.next}
            </Button>
          </div>
        </>
      } />
    </>
  )
}

const mapStateToProps = state => ({
  appState: state.appState,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators(actions.appActions, dispatch),
  userActions: bindActionCreators(actions.userActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthGenreForm);
