import styles from './TextField.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

/**
 *
 * @param {Object} props
 * @param {string} [props.className]
 * @param {Object} [props.inputProps]
 * @returns {JSX.Element}
 */
const TextField = (props) => {
  const { id, value, inputProps, onChange, className: rootClassName } = props
  const className = cx(styles.root, rootClassName)

  // const {
  //   field,
  //   fieldState: { error },
  // } = useController(props)

  return (
    <>
      <div className={className}>
        <textarea
          id={id}
          value={value}
          {...inputProps}
          onChange={(e) => onChange(e.target.value)}
          // onBlur={field.onBlur}
        ></textarea>
      </div>
      {/*{error ? <span className={cx(styles.add_text, styles.error)}>{error.message}</span> : null}*/}
    </>
  )
}

export default TextField
