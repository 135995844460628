import styles from './Button.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

/**
 *
 * @param {Object} props
 * @param {string} [props.className]
 * @returns {JSX.Element}
 */
const Button = (props) => {
  const {
    width = '',
    variant = 'text',
    color = '',
    type = 'button',
    fullWidth = false,
    children,
    className: rootClassName,
    onClick,
    buttonProps,
  } = props

  const className = cx(styles.root, rootClassName, styles[variant], styles[color], {
    full_width: fullWidth,
  })

  const handleClick = (event) => {
    if (onClick) {
      onClick(event)
    }
  }

  return (
    <>
      <button
        type={type}
        className={className}
        onClick={handleClick}
        style={{ width }}
        {...buttonProps}
      >
        {children}
      </button>
    </>
  )
}

export default Button
