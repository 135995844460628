import styles from './EventCell.module.scss'
import classNames from 'classnames/bind'
import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import actions from "../../redux/actions";
import { connect } from "react-redux";
import strings from '@/lang/strings'

const cx = classNames.bind(styles)

const EventCell = ({
  style: rootStyle,
  aspectRatio = 0.82,
  className: rootClassName,
  user: {user},
  title,
  date,
  location,
  distance,
  item,
  width = null,
  showInfoOuter = false,
  pointType,
  onClick=null
}) => {
  const className = cx(styles.root, rootClassName)

  const mainImage = item?.images[0]

  return (
    <div
      className={className}
      style={rootStyle}
      onClick={() => {
        onClick && onClick()
      }}
    >
      <div className={styles.image_background} style={{width: width, aspectRatio: aspectRatio}} />
      <div className={styles.image_wrapper} style={{width: width, aspectRatio: aspectRatio}}>
        <img
          width={"100%"}
          height={"100%"}
          src={mainImage}
          loading="lazy"
        />
      </div>
      {!showInfoOuter && (
        <div className={styles.inner_info_container} style={{width: width}}>
          <div className={styles.inner_title}>{title}</div>
          <div className={"mb-12"}>
            <div className={styles.inner_desc}>{date}</div>
            <div className={styles.inner_desc}>
              {location}
            </div>
          </div>
        </div>
      )}
      {showInfoOuter && (
        <div className={styles.outer_info_container} style={{width: width}}>
          <div className={styles.outer_title}>{title}</div>
          <div>
            <div className={styles.outer_desc}>{date}</div>
            <div className={styles.outer_desc}>
              {location}
            </div>
          </div>
        </div>
      )}
      <div className={cx(styles.badge, pointType == strings.home.plus_type ? 'plus' : 'minus')}>{pointType == strings.home.plus_type ? strings.home.plus : strings.home.minus}</div>
    </div>
  );
};


const mapStateToProps = state => ({
  appState: state.appState,
  user: state.user,
  festival: state.festival,
});

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators(actions.appActions, dispatch),
  userActions: bindActionCreators(actions.userActions, dispatch),
  festivalActions: bindActionCreators(actions.festivalActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventCell);
