import {handleActions} from 'redux-actions';

const initialState = {
  address: '',
  latitude: '',
  longitude: '',
  gather: {},
  festival: {},
  editGather: {},
};

export default handleActions(
  {
    'CLASS/SET_ADDRESS': (state, action) =>
      Object.assign({}, state, {
        address: action.payload,
      }),
    'CLASS/SET_COORDS': (state, action) =>
      Object.assign({}, state, {
        latitude: action.payload?.latitude,
        longitude: action.payload?.longitude,
      }),
    'CLASS/SET_GATHER': (state, action) =>
      Object.assign({}, state, {
        gather: action.payload,
      }),
    'CLASS/SET_FESTIVAL': (state, action) =>
      Object.assign({}, state, {
        festival: action.payload,
      }),
    'CLASS/SET_EDIT_GATHER': (state, action) =>
      Object.assign({}, state, {
        editGather: action.payload,
      }),
  },
  initialState,
);
