import {handleActions} from 'redux-actions';

const initialState = {
  event: {},
};

export default handleActions(
  {
    'EVENT/SET_EVENT': (state, action) =>
      Object.assign({}, state, {
        event: action.payload,
      }),
  },
  initialState,
);
