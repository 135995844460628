import en from "./locales/en";
import ko from "./locales/ko";
import LocalizedStrings from 'react-localization';

let strings = new LocalizedStrings({
  en: en,
  ko: ko
});
strings.setLanguage('ko');

export default strings;
