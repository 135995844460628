import styles from "./TermView.module.scss"
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames/bind'
import Icon from '@/components/Icon/Icon'

const cx = classNames.bind(styles)

const TermView = (props) => {
  const {
    title = '',
    content = '',
    onClose
  } = props

  const handleClose = (event) => {
    if (onClose) {
      onClose(event)
    }
  }

  return (
    <>
      <div className={cx(styles.root, 'modal')}>
        <div className={cx(styles.close, 'p-14 mr-22')} onClick={handleClose}>
          <Icon variant="icon_close_white" className={"w-24 h-24"}/>
        </div>
        <div className={cx(styles.body, 'mh-40')}>
          <div className={cx(styles.title, 'mv-20')}>
            {title}
          </div>
          <div className={cx(styles.content, 'mh-24 mb-20')}>
            {content}
          </div>
        </div>
      </div>
    </>
  )
}

export default TermView
