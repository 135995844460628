import styles from './StepBar.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

/**
 *
 * @param {Object} props
 * @param {React.ReactNode} [props.children]
 * @param {string} [props.className]
 * @returns {JSX.Element}
 */
const StepBar = (props) => {
  const { max, seek, className: rootClassName } = props
  const className = cx(styles.root, rootClassName)

  return (
    <>
      <div className={className}>
        <div className={styles.step_bg}>
          <div className={styles.current_step} style={{width: ((seek/max) * 100) + '%'}} />
        </div>
        <span className={"w-30"}>{seek + '/' + max}</span>
      </div>
    </>
  )
}

export default StepBar
