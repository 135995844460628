import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.css'
import { Navigation, Pagination } from 'swiper'

const Carousel = ({RenderItem, data, children, isForHome,
                  showPagination=true, swiperProps}) => {
  const [index_, setIndex_] = useState(0)

  const swiper = useRef()
  let interval = null;

  return (
    <Swiper
      ref={swiper}
      {...swiperProps}
      centeredSlides={true}
      loop={true}
      spaceBetween={30}
      pagination={false}
      onInit={(swiper) => {
        if (!swiperProps.autoplay) {
          return
        }
        interval = setInterval(() => {
          if (swiper.destroyed) {
            clearInterval(interval)
            return
          }
          swiper.slideNext()
        }, 3000)
      }}
      modules={[Navigation, Pagination]}
    >
      {data.map((item, index) => (
        <SwiperSlide key={index}>
          <RenderItem item={item} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Carousel;
