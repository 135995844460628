
import Button from '@/components/button/Button'
import Icon from '@/components/Icon/Icon'
import strings from '@/lang/strings'
import AppleLogin from 'react-apple-login'
import { isExpired, decodeToken } from "react-jwt";

const SocialApple =(props)=>{
    const {
      onSuccess
    } = props

    const appleClientId = 'com.www.onfriends.net'
    const appleOnSuccess = (data)=>{
      	console.log(data)
        
      	console.log(data.authorization)
      	console.log(data.authorization.id_token)
        const myDecodedToken = decodeToken(data.authorization.id_token);
        console.log(myDecodedToken)
        console.log(myDecodedToken.sub)

        const sid = myDecodedToken.sub
      if (onSuccess) {
        if(data.user) {
        	 console.log(data.user.email)
            onSuccess(sid, data.user.email)
        } else {
            onSuccess(sid, '')
        }
      }
    }
    const appleOnFailure = (result) => {
        console.log(result);
    };
    return(
        <>
          <AppleLogin
              clientId={appleClientId}
              redirectURI="https://www.onfriends.net/login"
              callback={appleOnSuccess}
              scope={"name email"}  
              responseType={"code"} 
              responseMode={"form_post"}  
              usePopup={true} 
              render={({ onClick }) => (
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    onClick();
                  }}
                >
                  <Button color="grey" fullWidth={true} className={"mt-11"}>
                    <Icon variant="icon_sns2" className={"mr-7 w-22 h-23"} />
                    {strings.login.signin_with_apple}
                  </Button>
                </div>
              )}
          />
        </>
    )
}

export default SocialApple
