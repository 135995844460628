import styles from './InputText.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

/**
 *
 * @param {Object} props
 * @param {string} [props.className]
 * @param {any} [props.value]
 * @param {Object} [props.inputProps]
 * @returns {JSX.Element}
 */
const InputText = (props) => {
  const { type = 'text', id, value, inputProps, onChange, className: rootClassName } = props
  const className = cx(styles.root, rootClassName)

  const handleChange = (e) => {
    if (onChange) {
      if(props.inputProps && props.inputProps.pattern){
        if (e.target.value.match(props.inputProps.pattern) != null) {
          onChange(e.target.value)
        } else {
          onChange(value)
        }
      } else {
        onChange(e.target.value)
      }
    }
  }

  // const {
  //   field,
  //   fieldState: { error },
  // } = useController(props)

  return (
    <>
      <div className={className}>
        <input
          type={type}
          id={id}
          value={value}
          onChange={handleChange}
          // onBlur={field.onBlur}
          {...inputProps}
        />
      </div>
      {/*{error ? <span className={cx(styles.add_text, styles.error)}>{error.message}</span> : null}*/}
    </>
  )
}

export default InputText
