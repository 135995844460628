import styles from './Inquiry.module.scss'
import BasePage from '@/pages/BasePage'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames/bind'
import Header from '@/components/header/Header'
import Icon from '@/components/Icon/Icon'
import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import actions from '../../redux/actions'
import { connect } from 'react-redux'
import Footer from '@/components/footer/Footer'
import strings from '@/lang/strings'
import { sendRequest } from '@/common/common'
import imgSub05Top from '@/assets/images/img_sub_05_top@2x.png'
import Select from '@/components/select/Select'
import { InputText } from '@/components/input'
import TextField from '@/components/textField/TextField'

const cx = classNames.bind(styles)

const Inquiry = ({route, appState: {spinner}, appActions, userActions}) => {
  const navigate = useNavigate()
  const [type, setType] = useState('')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [content, setContent] = useState('')

  useEffect(() => {
  }, [])

  const handleInquiry = () => {
    if (!type) {
      appActions?.showAlertModal({
        text: strings.inquiry.select_inquiry_type,
      })
      return
    }
    if (!name) {
      appActions?.showAlertModal({
        text: strings.inquiry.enter_manager_name,
      })
      return
    }
    if (!phone) {
      appActions?.showAlertModal({
        text: strings.inquiry.enter_phone,
      })
      return
    }
    if (!email) {
      appActions?.showAlertModal({
        text: strings.inquiry.enter_email,
      })
      return
    }
    if (!content) {
      appActions?.showAlertModal({
        text: strings.inquiry.inquiry_content_placeholder,
      })
      return
    }

    sendRequest('common/inquiry/', 'POST', {
      type,
      name,
      phone,
      email,
      content
    }, data => {
      appActions?.showAlertModal({
        text: strings.inquiry.inquiry_success,
        onPressLeft: navigate(-1)
      })
    }, (data) => {
      appActions?.showAlertModal({
        text: data?.msg
      })
    })
  }

  return (
    <>
      <BasePage id={'inquiry'} className={cx(styles.root)} pageContent={
        <>
          <Header
            styles={{position: 'absolute'}}
            hasBack
            backIcon={'icon_back_white'}
            hasBackground={false}
            rightComp={
              <div style={{display: 'flex'}}>
                <div className={"w-30 h-40 center"} onClick={() => navigate('/search')}>
                  <Icon variant="search" className={"w-16 h-16"} />
                </div>
                <div className={"w-30 h-40 center mr-5"} onClick={() => appActions.showRightModal()}>
                  <Icon variant="icon_option" className={"w-16 h-16"} />
                </div>
              </div>
            }
          />
          <div className={styles.container}>
            <div className={styles.top}>
              <img src={imgSub05Top} className={"w100"} />
              <div className={styles.contents}>
                <div className={styles.title}>
                  {strings.notice.customer_inquiry}
                </div>
                <div className={cx(styles.desc, "mt-14")}>
                  {strings.notice.inquiry_desc}
                </div>
              </div>
            </div>
            <div className={cx("mh-12")}>
              <div className={styles.form_container}>
                <div className={styles.label}>
                  {strings.inquiry.inquiry_type}
                  <div className={cx(styles.required)}>{strings.inquiry.required}</div>
                </div>
                <Select className={styles.form_control} iconVariant={"icon_down_arrow_2"} onChange={(e, v) => setType(v)} defaultValue={type}>
                  <option value={''} key={0} disabled={true}>
                    {strings.inquiry.inquiry_type_placeholder}
                  </option>
                  {
                    strings.inquiry.inquiry_types.map((value, index) => (
                      <option value={value} key={index + 1}>
                        {value}
                      </option>
                    ))
                  }
                </Select>
              </div>
              <div className={styles.form_container}>
                <div className={styles.label}>
                  {strings.inquiry.manager_name}
                  <div className={cx(styles.required)}>{strings.inquiry.required}</div>
                </div>
                <InputText
                  type="text"
                  value={name}
                  className={styles.form_control}
                  onChange={(v) => setName(v)}
                />
              </div>
              <div className={styles.form_container}>
                <div className={styles.label}>
                  {strings.inquiry.contract}
                  <div className={cx(styles.required)}>{strings.inquiry.required}</div>
                </div>
                <InputText
                  type="text"
                  value={phone}
                  className={styles.form_control}
                  onChange={(v) => setPhone(v)}
                />
              </div>
              <div className={styles.form_container}>
                <div className={styles.label}>
                  {strings.inquiry.email_address}
                  <div className={cx(styles.required)}>{strings.inquiry.required}</div>
                </div>
                <InputText
                  type="text"
                  value={email}
                  className={styles.form_control}
                  onChange={(v) => setEmail(v)}
                />
              </div>
              <div className={styles.form_container}>
                <div className={styles.label}>
                  {strings.inquiry.inquiry_content}
                  <div className={cx(styles.required)}>{strings.inquiry.required}</div>
                </div>
                <TextField
                  value={content}
                  inputProps={{
                    rows: 8,
                    placeholder: strings.inquiry.inquiry_content_placeholder
                  }}
                  className={cx(styles.form_control, "pv-18")}
                  onChange={(v) => setContent(v)}
                />
              </div>
            </div>
            <div className={cx(styles.button_wrapper, "mv-28")} onClick={handleInquiry}>
              <div className={styles.button}>{strings.inquiry.inquiry}</div>
            </div>
          </div>
          <Footer backgroundColor={"#f3f3f3"} />
        </>
      } />
    </>
  )
}

const mapStateToProps = state => ({
  appState: state.appState,
  user: state.user,
  festival: state.festival
});

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators(actions.appActions, dispatch),
  userActions: bindActionCreators(actions.userActions, dispatch),
  festivalActions: bindActionCreators(actions.festivalActions, dispatch),
  eventActions: bindActionCreators(actions.eventActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Inquiry);
