import styles from "./Auth.module.scss"
import BasePage from '@/pages/BasePage'
import TopBar from '@/components/topbar/TopBar'
import strings from '@/lang/strings'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames/bind'
import Button from '@/components/button/Button'
import { InputText } from '@/components/input'
import StepBar from '@/components/stepBar/StepBar'
import SelectableBadge from '@/components/button/SelectableBadge'
import { bindActionCreators } from 'redux'
import actions from '@/redux/actions'
import { connect } from 'react-redux'

const cx = classNames.bind(styles)

const AuthLocationForm = ({appActions, userActions}) => {
  const navigate = useNavigate()

  const [locations, setLocations] = useState([])
  const [customRegion, setCustomRegion] = useState('')

  const handleNext = async () => {
    userActions.setUserInfo({
      interestRegion: locations.toString() + (isSelected(strings.auth.etc) ? ',' + customRegion : '')
    })
    navigate('/auth-festival-form');
  }

  const isSelected = (location) => {
    const index = locations.indexOf(location)
    if (index === -1) {
      return false
    } else {
      return true
    }
  }

  const onSelect = (location) => {
    const newLocations = Object.assign([], locations)
    const index = locations.indexOf(location)
    if (index === -1) {
      newLocations.push(location)
    } else {
      newLocations.splice(index, 1)
    }
    setLocations(newLocations)
    setCustomRegion(!isSelected(strings.auth.etc) ? "" : customRegion);
  }

  const isValid = locations?.length > 1 || locations?.length > 0 && !isSelected(strings.auth.etc) || customRegion;

  return (
    <>
      <BasePage id={"auth-location-form"} className={cx(styles.root, "h100")} pageContent={
        <>
          <TopBar title={strings.auth.custom_info_set} onBack={() => {
            navigate(-1);
          }} />
          <div className={cx(styles.container, "mh-16")}>
            <div style={{flex: 1}}>
              <StepBar seek={2} max={4} className={"mt-5"} />
              <div className={cx(styles.tip, 'mt-30')}>{strings.auth.select_interest_area}</div>
              <div className={cx(styles.badge_wrapper, 'mt-25')}>
                {
                  strings.auth.interest_regions.map((value, index) => {
                    const isSelect = isSelected(value)
                    return (
                      <SelectableBadge key={index} title={value} isSelected={isSelect} onSelect={onSelect}/>
                    )
                  })
                }
                {
                  isSelected(strings.auth.etc) &&
                  <InputText
                    type="text"
                    inputProps={{
                      placeholder: strings.auth.interest_area_placeholder,
                    }}
                    value={customRegion}
                    onChange={(v) => setCustomRegion(v)}
                  />
                }
              </div>
            </div>
            <Button color={isValid ? "orange" : "disable"} fullWidth={true} onClick={isValid && handleNext} className={"mt-5 mb-30"}>
              {strings.next}
            </Button>
          </div>
        </>
      } />
    </>
  )
}

const mapStateToProps = state => ({
  appState: state.appState,
});

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators(actions.appActions, dispatch),
  userActions: bindActionCreators(actions.userActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthLocationForm);
