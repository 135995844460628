import styles from './CheckBox.module.scss'
import classNames from 'classnames/bind'
import Icon from '@/components/Icon/Icon'

const cx = classNames.bind(styles)

/**
 *
 * @param {Object} props
 * @param {string} [props.className]
 * @returns {JSX.Element}
 */
const CheckBox = (props) => {
  const {
    className: rootClassName,
    onClick,
    iconName = "check_off",
    checkedIconName = 'check_on',
    text,
    checked = false,
  } = props

  const className = cx(styles.root, rootClassName)

  const handleClick = (event) => {
    if (onClick) {
      onClick(event)
    }
  }

  return (
    <>
      <div
        className={className}
        onClick={handleClick}
      >
        <Icon variant={checked ? checkedIconName : iconName} className={"mr-10 w-21 h-21"}  />
        {text}
      </div>
    </>
  )
}

export default CheckBox
