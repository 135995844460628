import styles from './Notice.module.scss'
import BasePage from '@/pages/BasePage'
import { useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames/bind'
import Header from '@/components/header/Header'
import Icon from '@/components/Icon/Icon'
import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import actions from '../../redux/actions'
import { connect } from 'react-redux'
import Footer from '@/components/footer/Footer'
import strings from '@/lang/strings'
import { sendRequest } from '@/common/common'
import imgSub04Top from '@/assets/images/img_sub_04_top@2x.png'
import NoticeCell from '@/components/home/NoticeCell'

const cx = classNames.bind(styles)

const Notice = ({route, appState: {spinner}, appActions, userActions}) => {
  const navigate = useNavigate()
  const params = useParams()

  const [notices, setNotices] = useState([])
  const [currentId, setCurrentId] = useState(params.id ? params.id : 0)

  useEffect(() => {
    getNotices()
  }, [])

  const getNotices = () => {
    sendRequest('common/notice/', 'GET', null, data => {
      setNotices(data)
    })
  }

  return (
    <>
      <BasePage id={'notice'} className={cx(styles.root)} pageContent={
        <>
          <Header
            styles={{position: 'absolute'}}
            hasBack
            backIcon={'icon_back_white'}
            hasBackground={false}
            rightComp={
              <div style={{display: 'flex'}}>
                <div className={"w-30 h-40 center"} onClick={() => navigate('/search')}>
                  <Icon variant="search" className={"w-16 h-16"} />
                </div>
                <div className={"w-30 h-40 center mr-5"} onClick={() => appActions.showRightModal()}>
                  <Icon variant="icon_option" className={"w-16 h-16"} />
                </div>
              </div>
            }
          />
          <div className={styles.top}>
            <img src={imgSub04Top} className={"w100"} />
            <div className={styles.contents}>
              <div className={styles.title}>
                {strings.notice.notice}
              </div>
              <div className={cx(styles.desc, "mt-14")}>
                {strings.notice.notice_desc}
              </div>
            </div>
          </div>
          <div style={{flex: 1}}>
            {
              notices.map((item, index) => {
                return (
                  <NoticeCell
                    key={index}
                    id={item?.id}
                    title={item?.title}
                    content={item?.content}
                    index={index}
                    currentId={currentId} />
                )
              })
            }
          </div>
          <Footer backgroundColor={"#f3f3f3"} />
        </>
      } />
    </>
  )
}

const mapStateToProps = state => ({
  appState: state.appState,
  user: state.user,
  festival: state.festival
});

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators(actions.appActions, dispatch),
  userActions: bindActionCreators(actions.userActions, dispatch),
  festivalActions: bindActionCreators(actions.festivalActions, dispatch),
  eventActions: bindActionCreators(actions.eventActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notice);
