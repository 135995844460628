// import {isIphoneX, getBottomSpace} from 'react-native-iphone-x-helper';
import React from 'react';
import strings from '@/lang/strings'

export var API_URL = 'https://api.onfriends.net/';
// export var API_URL = 'http://139.150.69.235:8000/';

export var DEEP_LINK = 'https://yw71j.app.link/onfriends';

export var isOffLine = true;

export var UI_DEV_MODE = false;

export var SUCCESS = 1;
export var FAIL = -1;
export var AUTH_FAIL = -2;
export var EXPIRED_KEY = -3;
export var ID_DUP = -4;
export var EMAIL_DUP = -5;
export var RESIGN = -6;
export var ID_NOT_EXIST = -7;
export var PASSWORD_FAIL = -8;
export var COMMUNITY_COMMENT_DUP = -9;

export var KAKAO = strings.kakao;
export var GOOGLE = strings.google;
export var NAVER = strings.naver;
export var APPLE = strings.apple;
export var INSTAGRAM = strings.instagram;

Object.size = function (obj) {
  var size = 0,
    key;
  for (key in obj) {
    if (obj.hasOwnProperty(key)) size++;
  }
  return size;
};

export const storeData = async data => {
  for (const key in data) {
    localStorage.setItem(key, data[key])
  }
};

export const retrieveData = async keys => {
  let data = {};
  keys.map((key) => {
    data[key] = localStorage.getItem(key);
  })

  return data;
};

export const removeData = async keys => {
  keys.map((key) => {
    localStorage.removeItem(key);
  })
};

export function range(size, startAt = 0) {
  return [...Array(size).keys()].map(i => i + startAt);
}

export function sendRequest(url, method, body, onSuccess, onError, token) {
  retrieveData(['accessToken']).then(data => {
    fetch(API_URL + url, {
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: (data?.accessToken || token) ? ('Bearer ' + (token ? token : data.accessToken)) : null,
      },
      body:
        ['POST', 'PUT', 'PATCH', 'DELETE'].indexOf(method) !== -1 ? JSON.stringify(body) : null,
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.linusCode === SUCCESS) {
          if (onSuccess) {
            onSuccess(responseJson.data, responseJson.page);
          }
        } else {
          if(responseJson.linusCode === -2) {
            console.log('auth error')
            removeData(['accessToken', 'id']).then(() => {
              sendRequest(url, method, body, onSuccess, onError, token)
            })
            return
          }
          if (onError) {
            onError(responseJson.data, responseJson.linusCode);
          } else {
            console.log(
              (responseJson.data ? responseJson.data.msg : 'Server error') +
                ' [' +
                responseJson.linusCode +
                '] ' +
                url,
            );
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  });
}

export function sendFormRequest(url, method, body, onSuccess, onError) {
  if (['POST', 'PUT'].indexOf(method) === -1) {
    alert('Error on method!');
    return;
  }
  retrieveData(['accessToken']).then(data => {
    fetch(API_URL + url, {
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: data.accessToken,
      },
      body: body,
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.linusCode === SUCCESS) {
          if (onSuccess) {
            onSuccess(responseJson.data);
          }
        } else {
          if (onError) {
            onError(responseJson.data, responseJson.linusCode);
          } else {
            console.log(
              (responseJson.data ? responseJson.data.msg : '서버 에러') +
                ' [' +
                responseJson.linusCode +
                '] ' +
                url,
            );
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  });
}

export function sendNaverOcrRequest(image, lang, onSuccess, onError) {
  fetch(
    'https://htrqxdjl97.apigw.ntruss.com/custom/v1/14445/9e6c1f1e22f716f1abb271bba68419daf0fd77c032874e214494ad1fb56b318e/general',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-OCR-SECRET': 'SWhkaVZocEtFRXRoSXFMTmZISHRDdnNUSVRJanZpaEM=',
      },
      body: JSON.stringify({
        images: [
          {
            format: 'jpg',
            name: 'medium',
            data: image,
          },
        ],
        lang: lang,
        requestId: new Date().getTime(),
        resultType: 'string',
        timestamp: new Date().getTime(),
        version: 'V2',
      }),
    },
  )
    .then(response => response.json())
    .then(responseJson => {
      if (responseJson?.requestId) {
        if (onSuccess) {
          onSuccess(responseJson);
        }
      } else {
        if (onError) {
          onError(responseJson, responseJson?.requestId);
        } else {
          console.log(JSON.stringify(responseJson));
          console.log(
            responseJson?.requestId
              ? responseJson?.requestId.toString() + 'Server error'
              : '',
          );
        }
      }
    })
    .catch(error => {
      console.log(error);
    });
}

export function sendGoogleOcrRequest(image, onSuccess, onError) {
  fetch(
    'https://vision.googleapis.com/v1/images:annotate?key=AIzaSyBtTETmDkpY9yTTLZrWRaZxTNA4y6hEq-o',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        requests: [
          {
            image: {
              content: image,
            },
            features: [
              {
                type: 'TEXT_DETECTION',
                maxResults: 1000,
              },
            ],
          },
        ],
      }),
    },
  )
    .then(response => response.json())
    .then(responseJson => {
      if (responseJson?.responses?.length > 0) {
        if (onSuccess) {
          onSuccess(responseJson?.responses[0]);
        }
      } else {
        if (onError) {
          onError(responseJson, FAIL);
        } else {
          console.log(JSON.stringify(responseJson));
          console.log('Server error');
        }
      }
    })
    .catch(error => {
      console.log(error);
    });
}

export const getTags = inputText => {
  if (!inputText) return [];
  const words = inputText.split(/(\s+)/);
  const formattedText = [];
  words.forEach((word, index) => {
    if (word.startsWith('#')) {
      formattedText.push(word.split('#').pop());
    }
  });
  return formattedText;
};

export function fillZero(value) {
  if (value >= 10) {
    return value.toString();
  } else {
    return '0' + value;
  }
}

export function getOrderedList(list, key) {
  list.sort((first, second) => {
    if (!first[key]) return null;
    return compareObjects(first, second, key);
  });
  return list;
}

export function compareObjects(object1, object2, key) {
  const obj1 = object1[key];
  const obj2 = object2[key];

  if (obj1 < obj2) {
    return 1;
  }
  if (obj1 > obj2) {
    return -1;
  }
  return 0;
}

export function isCloseToBottom({
  layoutMeasurement,
  contentOffset,
  contentSize,
}) {
  const paddingToBottom = 50;
  // console.log(layoutMeasurement.height + contentOffset.y, contentSize.height - paddingToBottom)
  return (
    layoutMeasurement.height + contentOffset.y >=
    contentSize.height - paddingToBottom
  );
}

export function intToMinuteSecond(time) {
  return fillZero(parseInt(time / 60)) + ':' + fillZero(parseInt(time % 60));
}

export const getDateTimeString = date => {
  if (!date) return ''
  return (
    date.getFullYear() +
    '.' +
    fillZero(date.getMonth() + 1) +
    '.' +
    fillZero(date.getDate()) +
    ' ' +
    fillZero(date?.getHours()) +
    ':' +
    fillZero(date?.getMinutes())

  );
};

export const getDateString = date => {
  if (!date) return ''
  return (
    date.getFullYear() +
    '.' +
    fillZero(date.getMonth() + 1) +
    '.' +
    fillZero(date.getDate())
  );
};

export function getDateStr(date) {
  return (
    date.getFullYear() +
    strings.year + ' ' +
    fillZero(date.getMonth() + 1) +
    strings.month + ' ' +
    fillZero(date.getDate()) +
    strings.day
  );
}

export function getDateStrFromObj(date) {
  return (
    date?.getFullYear() +
    '-' +
    fillZero(date?.getMonth() + 1) +
    '-' +
    fillZero(date?.getDate())
  );
}

export function getDateObj(dateObj) {
  return new Date(new Date().getFullYear(), dateObj.month - 1, dateObj.day);
}

export function getCustomObjFromDate(date) {
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
    weekday: date.getDay(),
  };
}

export var gradientColors = [
  [
    'rgba(250, 194, 142, 1)',
    'rgba(255, 138, 174, 1)',
    'rgba(250, 194, 142, 1)',
  ],
  ['rgba(150, 198, 255, 1)', 'rgba(103, 161, 229, 1)'],
  ['rgba(155, 239, 185, 1)', 'rgba(81, 199, 140, 1)'],
];

export function log(data) {
  // console.log(JSON.stringify(data))
}

export function log_(data) {
  // console.log(JSON.stringify(data))
}

export function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export const hitSlop = {
  top: 15,
  bottom: 15,
  left: 15,
  right: 15,
};

export const smallHitSlop = {
  top: 5,
  bottom: 5,
  left: 5,
  right: 5,
};

export const idCheck = id => {
  var re = new RegExp(/^(?=.*[a-zA-Z0-9]).{4,}$/);
  return re.test(id);
};

export const passwordCheck = password => {
  var re = new RegExp(
    /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$&()\\-`.+,/\"]{8,20}$/,
  );
  return re.test(password);
};

export function validateEmail(email) {
  var re =
    /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export const validator = (type, string) => {
  switch (type) {
    case 'password':
    case 'passwordConfirm':
      return passwordCheck(string);
    case 'email':
      return validateEmail(string);
    case 'phone':
      return validatePhone(string);
    default:
      return true;
  }
};

export const validatePhone = (string) => {
  var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
  if(string.match(phoneno)) {
    return true;
  }
  else {
    return false;
  }
}

export function hasWhiteSpace(s) {
  return s.indexOf(' ') >= 0;
}

export const setImageFormData = images => {
  const formData = new FormData();

  for (let idx in images) {
    const photo = images[idx];

    if (photo?.url) continue;

    let image = {
      uri: photo?.path ? photo?.path : photo?.url ? photo?.url : photo?.uri,
      type: 'image/jpg',
      name: photo?.fileName ? photo?.fileName : photo?.name,
    };
    formData.append(`image`, image);
  }
  return formData;
};

export const setImagesFormData = images => {
  const formData = new FormData();

  for (let idx in images) {
    const photo = images[idx];

    if (photo?.url) continue;

    let image = {
      uri: photo?.path ? photo?.path : photo?.url ? photo?.url : photo?.uri ? photo?.uri : photo,
      type: 'image/jpg',
      name: photo?.fileName ? photo?.fileName : photo?.name ? photo?.name : 'image.jpg',
    };
    formData.append(`images`, image);
  }

  return formData;
};


export const getAlarms = alarmProducts => {
  let alarms = [];
  for (let idx in alarmProducts) {
    let alarmProduct = alarmProducts[idx];
    alarms = alarms.concat(alarmProduct.alarms);
  }
  return alarms;
};

String.prototype.hashCode = function () {
  var hash = 0,
    i,
    chr;
  if (this.length === 0) return hash;
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export const getDiscountRate = (originalPrice, discountedPrice) => {
  let original = originalPrice;
  let discounted = discountedPrice;

  if (typeof original === 'string') {
    original = original.replace(',', '');
  }

  if (typeof discounted === 'string') {
    discounted = discounted.replace(',', '');
  }

  return ((Number(original) - Number(discounted)) / Number(original)) * 100;
};


// export function getDistance(x1, y1, x2, y2) {
//   if(!x1 || !y1 || !x2 || !y2) {
//     return '0'
//   }
//   return (Math.hypot(x2-x1, y2-y1) * 100).toFixed(1)
// }

export function getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2-lat1);  // deg2rad below
  var dLon = deg2rad(lon2-lon1);
  var a =
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon/2) * Math.sin(dLon/2)
  ;
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  var d = R * c; // Distance in km
  return d.toFixed(1);
}

function deg2rad(deg) {
  return deg * (Math.PI/180)
}


export const getPhoneNumber = (p) => {
  return p.slice(0, 3) + '-' + p.slice(3, 7) + '-' + p.slice(7, 11)
}

export const groupBy = (xs, key) => {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export function numberWithCommas(x) {
  if (!x) return 0
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export const getDDay = (date) => {
  const today = new Date()
  const diff = date.getTime() - today.getTime()
  if (diff < 0) {
    return false
  }
  return (diff / (1000 * 3600 * 24)).toFixed(0)
}

export const nl2br = (str) => {
  return str.split('\n').map((line, i) => (
    <p key={i} className='m-0'>{line}</p>));
};

export const space2nbsp = (str) => {
  return str.split("").map((char, i) => (
    char == ' ' ?
      <>&nbsp;</>
      :
      <>{char}</>
  ));
};