import styles from './NoticeCell.module.scss'
import classNames from 'classnames/bind'
import React, { useState } from 'react'
import { bindActionCreators } from "redux";
import actions from "../../redux/actions";
import { connect } from "react-redux";
import Icon from '@/components/Icon/Icon'

const cx = classNames.bind(styles)

const NoticeCell = ({
  className: rootClassName,
  id,
  title,
  content,
  index,
  currentId
}) => {
  const className = cx(styles.root, rootClassName)

  const [showContent, setShowContent] = useState(currentId ? id == currentId ? true : false : index === 0 ? true : false);

  return (
    <div className={className}>
      <div className={styles.cell_container} onClick={() => {
          setShowContent(!showContent);
        }}>
        <div className={styles.menu_text}>{title}</div>
        <Icon
          variant={"icon_dropdown"}
          className={cx(showContent && "rotate_icon", "w-12 h-12")}
        />
      </div>
      {showContent && (
        <div className={cx(styles.contents, "mh-12 mv-10")}>{content}</div>
      )}
    </div>
  );
};


const mapStateToProps = state => ({
  appState: state.appState,
  user: state.user,
  festival: state.festival,
});

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators(actions.appActions, dispatch),
  userActions: bindActionCreators(actions.userActions, dispatch),
  festivalActions: bindActionCreators(actions.festivalActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NoticeCell);
