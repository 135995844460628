import styles from './SelectableBadge.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

/**
 *
 * @param {Object} props
 * @param {string} [props.className]
 * @returns {JSX.Element}
 */
const SelectableBadge = (props) => {
  const {
    className: rootClassName,
    variant = 'dark',
    onSelect,
    isSelected,
    title
  } = props

  const className = cx(styles.root, styles[variant], rootClassName)

  const handleSelect = (event) => {
    if (onSelect) {
      onSelect(title)
    }
  }

  return (
    <>
      <div
        className={cx(className, isSelected ? 'selected' : '')}
        onClick={handleSelect}
      >
        {title}
      </div>
    </>
  )
}

export default SelectableBadge
