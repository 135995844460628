
import Button from '@/components/button/Button'
import Icon from '@/components/Icon/Icon'
import strings from '@/lang/strings'
import NaverLogin from 'react-naver-login';

const SocialNaver =(props)=>{
    const {
      onSuccess
    } = props

    const naverClientId = 'vsapg2CvAFR1hhuOZ27y'
    const naverOnSuccess = (naverUser)=>{
      	console.log(naverUser)
      if (onSuccess) {
        onSuccess(naverUser.id, naverUser.email)
      }
    }
    const naverOnFailure = (result) => {
        console.log(result);
    };
    return(
        <>
          <NaverLogin
              clientId={naverClientId}
              callbackUrl="https://www.onfriends.net/login"
              onSuccess={naverOnSuccess}
              onFailure={naverOnFailure}
              render={({ onClick }) => (
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    onClick();
                  }}
                >
                  <Button color="green" fullWidth={true} className={"mt-11"}>
                    <Icon variant="icon_sns4" className={"mr-7 w-22 h-23"} />
                    {strings.login.signin_with_naver}
                  </Button>
                </div>
              )}
          />
        </>
    )
}

export default SocialNaver
