import {handleActions} from 'redux-actions';
import { flattenToSet } from "@babel/core/lib/config/helpers/deep-array";

const initialState = {
  user: {},
  isLogin: false,
  notifications: [],
  alert: false,
  tokens: {},
  userLocation: {},
  snsType: '',
  originLanguage: {name: '영어', value: 'ENGLISH'},
  transLanguage: {name: '한국어', value: '한국어'},
};

export default handleActions(
  {
    'USER/SET_USER_INFO': (state, action) =>
      Object.assign({}, state, {
        user: {
          ...state.user,
          ...action.payload,
        },
      }),
    'USER/DELETE_USER_INFO': (state) => Object.assign({}, state, {
      user: {},
    }),
    'USER/SET_IS_LOGIN': (state, action) =>
      Object.assign({}, state, {
        isLogin: action.payload,
      }),
    'USER/SET_NOTIFICATIONS': (state, action) =>
      Object.assign({}, state, {
        notifications: action.payload,
      }),
    'USER/SET_ALERT': (state, action) =>
      Object.assign({}, state, {
        alert: action.payload,
      }),
    'USER/SET_TOKENS': (state, action) =>
      Object.assign({}, state, {
        tokens: action.payload,
      }),
    'USER/SET_USER_LOCATION': (state, action) =>
      Object.assign({}, state, {
        userLocation: action.payload,
      }),
    'USER/SET_SNS_TYPE': (state, action) =>
      Object.assign({}, state, {
        snsType: action.payload,
      }),
    'USER/SET_ORIGIN_LANGUAGE': (state, action) =>
      Object.assign({}, state, {
        originLanguage: action.payload,
      }),
    'USER/SET_TRANS_LANGUAGE': (state, action) =>
      Object.assign({}, state, {
        transLanguage: action.payload,
      }),
  },
  initialState,
);
