import {handleActions} from 'redux-actions';

const initialState = {
  popup: {
    isVisible: false,
    title: null,
    components: null,
    answers: [],
    cancelable: true,
  },

  bottomModal: {
    title: '',
    isVisible: false,
    buttons: [],
  },

  actionModal: {
    isVisible: false,
    options: [],
    onPress: () => {},
    onPressInit: () => {},
    onPressApply: () => {},
  },

  spinner: {
    visible: false,
  },

  bottomNavigation: {
    visible: true,
  },

  alertModal: {
    isVisible: false,
    isCancelable: true,
    text: '',
    leftBtnText: '',
    rightBtnText: '',
    onPressLeft: () => {},
    onPressRight: () => {},
  },

  shareModal: {
    isVisible: false,
    isCancelable: true,
    text: '',
    onPressGoogle: () => {},
    onPressApp: () => {},
  },

  customModal: {
    isVisible: false,
    title: '',
    text: '',
    btnText: '확인',
    component: null,
    onPress: () => {},
    isCancelable: true,
  },

  customAlert: {
    isVisible: false,
    title: '',
    text: '',
    rightBtnText: '확인',
    rightBtnColor: '#ef5f2c',
    onPressRightBtn: () => {},
    isCancelable: true,
    isWritable: false,
    inputText: '',
    onChangeInputText: () => {},
    warningText: '',
  },

  filterModal: {
    isVisible: false,
    onPress: () => {},
    onPressInit: () => {},
    onPressApply: () => {},
  },

  partnerFilterModal: {
    isVisible: false,
    onPress: () => {},
    onPressInit: () => {},
    onPressApply: () => {},
  },

  communityFormModal: {
    isVisible: false,
    onPress: () => {},
    onPressSubmit: () => {},
  },

  currentRouteName: '',

  agreements: [],

  tintingBrands: [],
  carBrands: [],
  services: [],
  timeRanges: [],
  priceRanges: [],

  notice: {},

  rightModal: {
    isVisible: false,
    onPress: () => {},
  },
};

export default handleActions(
  {
    'APP/SHOW_POPUP': (state, action) =>
      Object.assign({}, state, {
        popup: {
          isVisible: true,
          title: action.payload.title,
          components: action.payload.components,
          answers: action.payload.answers,
          cancelable: action.payload.cancelable,
        },
      }),
    'APP/HIDE_POPUP': state =>
      Object.assign({}, state, {popup: initialState.popup}),

    'APP/SHOW_CUSTOM_MODAL': (state, action) =>
      Object.assign({}, state, {
        customModal: {
          isVisible: true,
          ...action.payload,
        },
      }),
    'APP/HIDE_CUSTOM_MODAL': state =>
      Object.assign({}, state, {
        customModal: {
          isVisible: false,
        },
      }),

    'APP/SHOW_CUSTOM_ALERT': (state, action) =>
      Object.assign({}, state, {
        customAlert: {
          isVisible: true,
          ...action.payload,
        },
      }),
    'APP/HIDE_CUSTOM_ALERT': state =>
      Object.assign({}, state, {
        customAlert: {
          isVisible: false,
        },
      }),

    'APP/SHOW_FILTER_MODAL': (state, action) =>
      Object.assign({}, state, {
        filterModal: {
          isVisible: true,
          ...action.payload,
        },
      }),

    'APP/SHOW_PARTNER_FILTER_MODAL': (state, action) =>
      Object.assign({}, state, {
        partnerFilterModal: {
          isVisible: true,
          ...action.payload,
        },
      }),

    'APP/SHOW_ACTION_MODAL': (state, action) =>
      Object.assign({}, state, {
        actionModal: {
          isVisible: true,
          ...action.payload,
        },
      }),

    'APP/HIDE_ACTION_MODAL': (state, action) =>
      Object.assign({}, state, {
        actioinModal: {
          isVisible: false,
        },
      }),

    'APP/HIDE_FILTER_MODAL': (state, action) =>
      Object.assign({}, state, {
        filterModal: {
          isVisible: false,
        },
      }),

    'APP/HIDE_PARTNER_FILTER_MODAL': (state, action) =>
      Object.assign({}, state, {
        partnerFilterModal: {
          isVisible: false,
        },
      }),

    'APP/SHOW_COMMUNITY_FORM_MODAL': (state, action) => {
      return Object.assign({}, state, {
        communityFormModal: {
          isVisible: true,
          ...action.payload,
        },
      });
    },

    'APP/HIDE_COMMUNITY_FORM_MODAL': (state, action) =>
      Object.assign({}, state, {
        communityFormModal: {
          isVisible: false,
        },
      }),

    'APP/SHOW_BOTTOM_MODAL': (state, action) =>
      Object.assign({}, state, {
        bottomModal: {
          isVisible: true,
          ...action.payload,
        },
      }),

    'APP/HIDE_BOTTOM_MODAL': state =>
      Object.assign({}, state, {bottomModal: initialState.bottomModal}),

    'APP/SHOW_ALERT_MODAL': (state, action) =>
      Object.assign({}, state, {
        alertModal: {
          isVisible: true,
          ...action.payload,
        },
      }),

    'APP/HIDE_ALERT_MODAL': state =>
      Object.assign({}, state, {alertModal: initialState.alertModal}),

    'APP/SHOW_SHARE_MODAL': (state, action) =>
      Object.assign({}, state, {
        shareModal: {
          isVisible: true,
          ...action.payload,
        },
      }),

    'APP/HIDE_SHARE_MODAL': state =>
      Object.assign({}, state, {shareModal: initialState.shareModal}),

    'APP/SHOW_NAVIGATION': (state, action) =>
      Object.assign({}, state, {
        navigation: {
          isShow: true,
        },
      }),

    'APP/HIDE_NAVIGATION': (state, action) =>
      Object.assign({}, state, {
        navigation: {
          isShow: false,
        },
      }),

    'APP/SHOW_SPINNER': (state, action) =>
      Object.assign({}, state, {
        spinner: {
          visible: true,
        },
      }),

    'APP/HIDE_SPINNER': (state, action) =>
      Object.assign({}, state, {
        spinner: {
          visible: false,
        },
      }),

    'APP/SET_NAVIGATION_INDEX': (state, action) =>
      Object.assign({}, state, {
        navigationIndex: action.payload,
      }),

    'APP/SET_CURRENT_ROUTE_NAME': (state, action) =>
      Object.assign({}, state, {
        currentRouteName: action.payload,
      }),

    'APP/SHOW_REPORT_MODAL': (state, action) =>
      Object.assign({}, state, {
        reportModal: {
          isVisible: true,
          ...action.payload,
        },
      }),

    'APP/SET_VERSION_INFO': (state, action) =>
      Object.assign({}, state, {
        versionInfo: action.payload,
      }),

    'APP/SET_AGREEMENTS': (state, action) =>
      Object.assign({}, state, {
        agreements: action.payload,
      }),
    'APP/SET_CONFIG_INFO': (state, action) =>
      Object.assign({}, state, {
        ...action.payload,
      }),
    'APP/SET_NOTICE': (state, action) =>
      Object.assign({}, state, {
        notice: action.payload,
      }),
    'APP/SHOW_RIGHT_MODAL': (state, action) =>
      Object.assign({}, state, {
        rightModal: {
          isVisible: true,
          ...action.payload,
        },
      }),

    'APP/HIDE_RIGHT_MODAL': (state, action) =>
      Object.assign({}, state, {
        rightModal: {
          isVisible: false,
        },
      }),
  },
  initialState,
);
