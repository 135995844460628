import {createActions} from 'redux-actions';

export default {
  appActions: createActions(
    {
      SHOW_POPUP: (title, components, answers) => ({
        title,
        components,
        answers,
      }),
      HIDE_POPUP: () => ({}),
      SHOW_MODAL: data => data,
      HIDE_MODAL: () => ({}),
      SHOW_BOTTOM_MODAL: data => data,
      HIDE_BOTTOM_MODAL: () => ({}),
      SHOW_ACTION_MODAL: data => data,
      HIDE_ACTION_MODAL: () => ({}),
      SHOW_CUSTOM_MODAL: data => data,
      HIDE_CUSTOM_MODAL: () => ({}),
      SHOW_CUSTOM_ALERT: data => data,
      HIDE_CUSTOM_ALERT: () => ({}),
      SHOW_SEARCH_MODAL: data => data,
      HIDE_SEARCH_MODAL: () => ({}),
      SHOW_ALERT_MODAL: data => data,
      HIDE_ALERT_MODAL: () => ({}),
      SHOW_SHARE_MODAL: data => data,
      HIDE_SHARE_MODAL: () => ({}),
      SHOW_FILTER_MODAL: data => data,
      HIDE_FILTER_MODAL: () => ({}),
      SHOW_COMMUNITY_FORM_MODAL: data => data,
      HIDE_COMMUNITY_FORM_MODAL: () => ({}),
      SHOW_NAVIGATION: () => ({}),
      HIDE_NAVIGATION: () => ({}),
      SHOW_SPINNER: () => ({}),
      HIDE_SPINNER: () => ({}),
      SET_NAVIGATION_INDEX: data => data,
      SET_CURRENT_ROUTE_NAME: data => data,
      SET_VERSION_INFO: data => data,
      SET_AGREEMENTS: data => data,
      SET_CONFIG_INFO: data => data,
      SHOW_PARTNER_FILTER_MODAL: data => data,
      HIDE_PARTNER_FILTER_MODAL: () => ({}),
      SET_NOTICE: data => data,
      SHOW_RIGHT_MODAL: data => data,
      HIDE_RIGHT_MODAL: data => data,
    },
    {prefix: 'APP'},
  ),
  userActions: createActions(
    {
      SET_USER_INFO: user => ({
        ...user,
      }),
      DELETE_USER_INFO: () => ({}),
      SET_RECENT_PRODUCTS: data => data,
      SET_LIKE_PRODUCTS: data => data,
      SET_AVG_INFO: data => data,
      SET_ALARM_PRODUCTS: data => data,
      SET_ALARMS: data => data,
      SET_CHECKED_PRODUCTS: data => data,
      SET_IS_LOGIN: data => data,
      SET_NOTIFICATIONS: data => data,
      SET_ALERT: data => data,
      SET_TOKENS: data => data,
      SET_USER_LOCATION: data => data,
      SET_SNS_TYPE: data => data,
      SET_ORIGIN_LANGUAGE: data => data,
      SET_TRANS_LANGUAGE: data => data,
    },
    {prefix: 'USER'},
  ),
  festivalActions: createActions(
    {
      SET_MUSICS: data => data,
      SET_CULTURES: data => data,
      SET_FOODS: data => data,
      SET_CONCERTS: data => data,
      SET_ETCS: data => data,
      SET_FESTIVAL_TYPE: data => data,
      SET_ADDRESS: data => data,
      SET_ORDERING: data => data,
      SET_MD_FESTIVALS: data => data,
      SET_RECOMMEND_FESTIVALS: data => data,
      SET_FESTIVAL: data => data,
      SET_TOUR_OR_STORE: data => data,
      SET_PERSON: data => data,
      SET_TIME_TABLE: data => data,
    },
    {prefix: 'FESTIVAL'},
  ),
    classActions: createActions(
      {
        SET_ADDRESS: data => data,
        SET_COORDS: data => data,
        SET_GATHER: data => data,
        SET_FESTIVAL: data => data,
        SET_EDIT_GATHER: data => data,
      },
      {prefix: 'CLASS'},
    ),
  eventActions: createActions(
    {
      SET_EVENT: data => data,
    },
    {prefix: 'EVENT'},
  ),
};
