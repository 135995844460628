import styles from './Home.module.scss'
import BasePage from '@/pages/BasePage'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames/bind'
import Header from '@/components/header/Header'
import Icon from '@/components/Icon/Icon'
import React, { useEffect, useState } from 'react'
import { getDateString, getDistanceFromLatLonInKm, sendRequest } from '@/common/common'
import strings from '@/lang/strings'
import Carousel from '@/components/carousel/Carousel'
import { bindActionCreators } from 'redux'
import actions from "../../redux/actions";
import { connect } from 'react-redux'
import imgMainTop from '@/assets/images/img_main_top@2x.png'
import imgMainVideo from '@/assets/images/img_main_video@2x.png'
import imgLogoOrange from '@/assets/images/img_logo_orrange@2x.png'
import imgDownloadThumb from '@/assets/images/img_download_thumb@2x.png'
import btnAppstore from '@/assets/images/btn_appstore@2x.png'
import btnPlayStore from '@/assets/images/btn_playstore@2x.png'
import SelectableBadge from '@/components/button/SelectableBadge'
import FestivalCell from '@/components/home/FestivalCell'
import Footer from '@/components/footer/Footer'
import EventCell from '@/components/home/EventCell'
import ReactPlayer from 'react-player'

const cx = classNames.bind(styles)

const Home = ({appState: {spinner}, user: {user, userLocation}, appActions, userActions, festivalActions, eventActions, classActions}) => {
  const navigate = useNavigate()

  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const [festivalCellWidth, setFestivalCellWidth] = React.useState(0);
  const [eventCellWidth, setEventCellWidth] = React.useState(0);
  const [events, setEvents] = useState([])
  const [festivals, setFestivals] = useState([])
  const [notices, setNotices] = useState([])
  const [festivalType, setFestivalType] = useState(strings.festival_types[0])
  const upperMenus = [
    {
      label: strings.home.on_friends,
      dimension: {
        width: 25,
        height: 24,
      },
      onClick: () => {
        navigate('/about')
      },
    },
    {
      label: strings.home.festival,
      dimension: {
        width: 15,
        height: 19,
      },
      onClick: () => {
        navigate('/festival')
      },
    },
    {
      label: strings.home.event,
      dimension: {
        width: 18,
        height: 17,
      },
      onClick: () => {
        navigate('/event')
      },
    },
    {
      label: strings.home.inquiry,
      dimension: {
        width: 24,
        height: 24,
      },
      onClick: () => {
        navigate('/inquiry')
      },
    },
  ];

  useEffect(() => {
    getData()
    updateCellWidth(screenWidth);
    const handleResizeWindow = () => updateCellWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, [])

  const updateCellWidth = (screenWidth) => {
    screenWidth = screenWidth > 1320 ? 1320 : screenWidth
    setScreenWidth(screenWidth)
    setFestivalCellWidth((screenWidth - (8 * 3 + 12 * 2) * (screenWidth > 767 ? 1.7 : 1)) / 4)
    setEventCellWidth((screenWidth - (8 + 12 * 2) * (screenWidth > 767 ? 1.7 : 1)) / 2)
  }

  const getData = () => {
    getEvents()
    getNotices()
    getFestivals(festivalType)
    // getUserLocation()
  }

  const getNotices = () => {
    sendRequest('common/notice/', 'GET', null, data => {
      // ?.filter(item => item?.isMain)
      setNotices(data)
    })
  }

  const getEvents = () => {
    sendRequest('common/event', 'GET', null, data => {
      setEvents(data);
    })
  }

  const getFestivals = (type) => {
    sendRequest('festival/festival/?festivalType=' + type + '&size=10', 'GET', null, data => {
      setFestivals(data)
    })
  }

  const onFestivalTypeSelect = (type) => {
    setFestivalType(type);

    getFestivals(type);
  }

  const onMenu = () => {
    appActions.showRightModal()
  }

  const handleAppStore = () => {
    window.open('https://itunes.apple.com/app/id1666153199', '_blank');
  }

  const handleGoogleStore = () => {
    window.open('https://play.google.com/store/apps/details?id=com.onfriends.org.rn', '_blank');
  }

  return (
    <>
      <BasePage id={'home'} className={cx(styles.root)} pageContent={
        <>
          <Header
            styles={{position: 'absolute'}}
            hasLogo
            hasBackground={false}
            rightComp={
              <div style={{display: 'flex'}}>
                <div className={"w-30 h-40 center"} onClick={() => navigate('/search')}>
                  <Icon variant="search" className={"w-16 h-16"} />
                </div>
                <div className={"w-30 h-40 center mr-5"} onClick={onMenu}>
                  <Icon variant="icon_option" className={"w-16 h-16"} />
                </div>
              </div>
            }
          />
          <div className={styles.top}>
            <img style={{width: '100%'}} src={imgMainTop} />
            <div className={styles.contents}>
              <div className={styles.title}>
                {strings.home.about_friends}
              </div>
              <div className={cx(styles.about, "mt-20")} onClick={() => navigate('/about')}>
                {strings.home.on_friends}
              </div>
            </div>
          </div>
          <div className={cx(styles.notice_and_upper_menu, "p-12")}>
            <div className={styles.notice_wrapper}>
              <Carousel
                data={notices}
                swiperProps={{
                  direction: 'vertical',
                  autoplay: true,
                }}
                RenderItem={({item, index}) => {
                  return (
                    <div className={styles.notice_item}
                         onClick={() => {
                           navigate('/notice/' + item.id)
                         }}
                    >
                      <div>
                        {item?.title}
                      </div>
                      <Icon variant="icon_arrow_right" className={"w-12 h-12"} />
                    </div>
                  )
                }}/>
            </div>
            <div className={cx(styles.upper_menu_wrapper, "mt-12")}>
              {upperMenus.map((i, idx) => (
                <div
                  key={i.label}
                  className={styles.upper_menu}
                  onClick={i.onClick}>
                  <div className={styles.upper_menu_icon}>
                    <Icon variant={"icon_category_" + (idx + 1)} className={"w-" + i.dimension.width + " h-" + i.dimension.height + " mb-3"} />
                  </div>
                  <div className={cx(styles.upper_menu_text, "mt-6")}>{i.label}</div>
                </div>
              ))}
            </div>
          </div>
          <div className={cx(styles.banner_wrapper, "ph-12")}>
            <div className={cx(styles.banner_tip, "mt-35")}>
              {strings.home.banner_tip}
            </div>
            <img src={imgLogoOrange} alt='logo' className={cx(styles.banner_logo, "mt-12")}/>
            <ReactPlayer
              className={cx(styles.youtube_wrapper, "mv-18")}
              width="100%"
              height="100%"
              url="https://www.youtube.com/watch?v=jftPNQhhpyQ&t=44s"
            />
            {/*<div className={styles.banner_swiper}>*/}
            {/*  <Carousel*/}
            {/*    data={events}*/}
            {/*    swiperProps={{*/}
            {/*      direction: 'horizontal'*/}
            {/*    }}*/}
            {/*    RenderItem={({item, index}) => {*/}
            {/*      return (*/}
            {/*        <div className={styles.banner_item}*/}
            {/*             onClick={() => {*/}
            {/*               // navigate('Notice', {notice: item})*/}
            {/*             }}*/}
            {/*        >*/}
            {/*          <img*/}
            {/*            src={`${item.images[0]}`}*/}
            {/*            alt={item.title}*/}
            {/*            loading="lazy"*/}
            {/*          />*/}
            {/*        </div>*/}
            {/*      )*/}
            {/*    }}/>*/}
            {/*</div>*/}
            <div className={cx(styles.banner_tip2)}>
              {strings.home.banner_tip2}<br/>
              <span>{strings.home.banner_tip3}</span>
              {strings.home.banner_tip4}
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.section_title}>
              {strings.home.festival}
            </div>
            <div className={styles.section_desc}>
              {strings.home.festival_desc}
            </div>
            <div className={cx(styles.festival_type_wrapper, "mt-17")}>
              {
                strings.festival_types.map((value, index) => {
                  return (
                    <SelectableBadge variant={'orange'} className={styles.selectable_badge} key={index} title={value} isSelected={value === festivalType} onSelect={onFestivalTypeSelect}/>
                  )
                })
              }
            </div>
            <div className={cx(styles.festival_wrapper, "mt-13")}>
              {
                festivals.slice(0, 4).map((item, index) => {
                  return (
                    <FestivalCell
                      key={index}
                      title={item.title}
                      date={getDateString(new Date(item.startDateTime)) + ' ~ ' + getDateString(new Date(item.endDateTime))}
                      location={item?.address}
                      distance={getDistanceFromLatLonInKm(item?.lat, item?.lon, userLocation.latitude, userLocation.longitude) + 'km'}
                      isLiked={item?.isLike}
                      item={item}
                      showInfoOuter={true}
                      width={festivalCellWidth}
                      style={{marginRight: (index % 4 !=3) && '8px'}}
                      onClick={() => {
                        festivalActions.setFestival(item)
                        navigate('/festival/' + item.id)
                      }}/>
                  )
                })
              }
            </div>
            <div className={styles.more_btn} onClick={() => navigate('/festival')}>
              <div className={styles.more_btn_text}>{strings.home.view_all}</div>
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.section_title}>
              {strings.home.event}
            </div>
            <div className={styles.section_desc}>
              {strings.home.event_desc}
            </div>
            <div className={cx(styles.festival_wrapper, "mt-17")}>
              {
                events.slice(0, 2).map((item, index) => {
                  return (
                    <EventCell
                      key={index}
                      title={item.title}
                      date={getDateString(new Date(item.startDateTime)) + ' ~ ' + getDateString(new Date(item.endDateTime))}
                      location={item?.address}
                      aspectRatio={1.91}
                      distance={getDistanceFromLatLonInKm(item?.lat, item?.lon, userLocation.latitude, userLocation.longitude) + 'km'}
                      item={item}
                      showInfoOuter={true}
                      pointType={item?.pointType}
                      width={eventCellWidth}
                      style={{marginRight: (index % 2 !=1) && '8px'}}
                      onClick={() => {
                        if (item.url) {
                          window.open(item.url, '_blank');
                        } else {
                          appActions?.showShareModal();
                        }
                      }}/>
                  )
                })
              }
            </div>
            <div className={styles.more_btn} onClick={() => navigate('/event')}>
              <div className={styles.more_btn_text}>{strings.home.view_all}</div>
            </div>
          </div>
          <div className={cx(styles.download_wrapper, 'mt-37')}>
            <img className={styles.download_image} src={imgDownloadThumb} />
            <div className={"ml-15"}>
              <div className={cx(styles.download_now)}>{strings.home.download_now}</div>
              <div className={cx(styles.download_tip, "mt-6")}>{strings.home.download_tip}</div>
              <div className={"mt-24"} style={{display: "flex"}}>
                <img src={btnAppstore} className={styles.download_btn} onClick={handleAppStore}/>
                <img src={btnPlayStore} className={cx(styles.download_btn, "ml-10")} onClick={handleGoogleStore}/>
              </div>
            </div>
          </div>
          <Footer />
        </>
      } />
    </>
  )
}

const mapStateToProps = state => ({
  appState: state.appState,
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators(actions.appActions, dispatch),
  userActions: bindActionCreators(actions.userActions, dispatch),
  festivalActions: bindActionCreators(actions.festivalActions, dispatch),
  eventActions: bindActionCreators(actions.eventActions, dispatch),
  classActions: bindActionCreators(actions.classActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
