import styles from './Search.module.scss'
import BasePage from '@/pages/BasePage'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames/bind'
import Header from '@/components/header/Header'
import Icon from '@/components/Icon/Icon'
import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import actions from "../../redux/actions";
import { connect } from 'react-redux'
import Footer from '@/components/footer/Footer'
import { InputText } from '@/components/input'
import strings from '@/lang/strings'
import FestivalCell from '@/components/home/FestivalCell'
import { getDateString, getDistanceFromLatLonInKm, sendRequest } from '@/common/common'
import EventCell from '@/components/home/EventCell'

const cx = classNames.bind(styles)

const Search = ({appState: {spinner}, user: {user, userLocation}, appActions, userActions, festivalActions, eventActions, classActions}) => {
  const navigate = useNavigate()

  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const [festivalCellWidth, setFestivalCellWidth] = React.useState(0);
  const [eventCellWidth, setEventCellWidth] = React.useState(0);
  const [searchKey, setSearchKey] = useState("")
  const [titles, setTitles] = useState([])
  const [festivals, setFestivals] = useState([])
  const [events, setEvents] = useState([])
  const [searchBtnClicked, setSearchBtnClicked] = useState(false)

  useEffect(() => {
    updateCellWidth(screenWidth);
    const handleResizeWindow = () => updateCellWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, [])

  const getTitles = (searchKey) => {
    sendRequest(`common/search/?keyword=${searchKey}`, 'GET', null, data => {
      setTitles(data);
    })
  }

  const getFestivals = (searchKey) => {
    sendRequest(`festival/festival/?keyword=${searchKey}`, 'GET', null, data => {
      setFestivals(data)
    })
  }

  const getEvents = (searchKey) => {
    sendRequest(`common/event/?keyword=${searchKey}`, 'GET', null, data => {
      setEvents(data)
    })
  }

  const updateCellWidth = (screenWidth) => {
    screenWidth = screenWidth > 1320 ? 1320 : screenWidth
    setScreenWidth(screenWidth)
    setFestivalCellWidth((screenWidth - (8 * 3 + 12 * 2) * (screenWidth > 767 ? 1.7 : 1)) / 4)
    setEventCellWidth((screenWidth - (8 + 12 * 2) * (screenWidth > 767 ? 1.7 : 1)) / 2)
  }

  const handleSearch = (searchKey) => {
    setSearchKey(searchKey)
    setSearchBtnClicked(true)
    getFestivals(searchKey)
    getEvents(searchKey)
  }

  const handleSearchKeyChanged = (searchKey) => {
    setSearchKey(searchKey)
    setSearchBtnClicked(false)

    if (searchKey) {
      getTitles(searchKey)
    } else {
      setTitles([])
    }
  }

  return (
    <>
      <BasePage id={'search'} className={cx(styles.root)} pageContent={
        <>
          <Header
            styles={{borderBottom: "1px solid #dddddd"}}
            hasBack
            centerComp={
              <div className={styles.search_wrapper}>
                <div className={styles.search_input_wrapper}>
                  <InputText
                    className={cx(styles.search_input, "ph-4")}
                    type="text"
                    value={searchKey}
                    onChange={(v) => handleSearchKeyChanged(v)}
                    inputProps={{
                      placeholder: ""
                    }}
                  />
                  <div className={"w-18 h-25 center"} onClick={() => handleSearchKeyChanged("")}>
                    <Icon variant="icon_input_close" className={"w-12 h-12"} />
                  </div>
                  <div className={"w-18 h-25 center mr-4"} onClick={() => handleSearch(searchKey)}>
                    <Icon variant="icon_search_orange" className={"w-12 h-12"} />
                  </div>
                </div>
                <div className={cx(styles.cancel_btn, "pl-8 pr-1 pv-10")} onClick={() => navigate(-1)}>
                  {strings.cancel}
                </div>
              </div>
            }
            rightComp={<div style={{flex: 0}} />}
          />
          <div className={styles.container}>
            {
              !searchBtnClicked ?
                <>
                  {
                    titles.map((item, index) => {
                      const keyword = searchKey.trim();
                      const title = item.title
                      const offset = title.toLowerCase().indexOf(keyword.toLowerCase())
                      return (
                        <div className={styles.title_cell} onClick={() => handleSearch(item.title)}>
                          <span>{title.slice(0, offset)}</span>
                          <span style={{fontWeight: "bold", color: "black"}}>{title.slice(offset, offset + keyword.length)}</span>
                          <span>{title.slice(offset + keyword.length)}</span>
                        </div>
                      )
                    })
                  }
                </>
                :
                <div>
                  <div className={styles.section}>
                    <div className={styles.section_title}>
                      {strings.home.festival}
                    </div>
                    <div className={cx(styles.festival_wrapper, "mt-13")}>
                      {
                        festivals.map((item, index) => {
                          return (
                            <FestivalCell
                              title={item.title}
                              date={getDateString(new Date(item.startDateTime)) + ' ~ ' + getDateString(new Date(item.endDateTime))}
                              location={item?.address}
                              distance={getDistanceFromLatLonInKm(item?.lat, item?.lon, userLocation.latitude, userLocation.longitude) + 'km'}
                              isLiked={item?.isLike}
                              item={item}
                              showInfoOuter={true}
                              width={festivalCellWidth}
                              style={{marginRight: (index % 4 !=3) && '8px'}}
                              onClick={() => {
                                festivalActions.setFestival(item)
                                navigate('/festival/' + item.id)
                              }}/>
                          )
                        })
                      }
                    </div>
                  </div>
                  <div className={styles.section}>
                    <div className={styles.section_title}>
                      {strings.home.event}
                    </div>
                    <div className={cx(styles.festival_wrapper, "mt-17")}>
                      {
                        events.map((item, index) => {
                          return (
                            <EventCell
                              title={item.title}
                              date={getDateString(new Date(item.startDateTime)) + ' ~ ' + getDateString(new Date(item.endDateTime))}
                              location={item?.address}
                              aspectRatio={1.91}
                              distance={getDistanceFromLatLonInKm(item?.lat, item?.lon, userLocation.latitude, userLocation.longitude) + 'km'}
                              item={item}
                              showInfoOuter={true}
                              pointType={item?.pointType}
                              width={eventCellWidth}
                              style={{marginRight: (index % 2 !=1) && '8px'}}
                              onClick={() => {
                                if (item.url) {
                                  window.open(item.url, '_blank');
                                }
                              }}/>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
            }
          </div>
          <Footer backgroundColor={"#f3f3f3"} />
        </>
      } />
    </>
  )
}

const mapStateToProps = state => ({
  appState: state.appState,
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators(actions.appActions, dispatch),
  userActions: bindActionCreators(actions.userActions, dispatch),
  festivalActions: bindActionCreators(actions.festivalActions, dispatch),
  eventActions: bindActionCreators(actions.eventActions, dispatch),
  classActions: bindActionCreators(actions.classActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
