const BasePage = (props) => {
    return (
        <>
            <div className={"page-content " + (props.className)} id={props.id}>
                {props.pageContent}
            </div>
        </>
    );
}

export default BasePage;
