import React from 'react';
import {bindActionCreators} from 'redux';
import actions from '../../redux/actions';
import {connect} from 'react-redux';
import Modal from 'react-modal'
import classNames from 'classnames/bind'
import styles from '@/components/modals/ShareModal.module.scss'
import strings from '@/lang/strings'
import btnAppstore from '@/assets/images/btn_appstore@2x.png'
import btnPlayStore from '@/assets/images/btn_playstore@2x.png'
import Icon from '@/components/Icon/Icon'

const cx = classNames.bind(styles)

const ShareModal = ({appState: {shareModal}, appActions}) => {
  const className = cx(styles.root)

  const hide = onPress => {
    appActions.hideShareModal();

    if (typeof onPress === 'function') {
      setTimeout(() => onPress(), 300);
    }
  };

  const onPressGoogle = () => {
    window.open('https://play.google.com/store/apps/details?id=com.onfriends.org.rn', '_blank');
  }

  const onPressApp = () => {
    window.open('https://itunes.apple.com/app/id1666153199', '_blank');
  }

  return (
    <Modal
      isOpen={shareModal.isVisible}
      key={'shareModal'}
      onRequestClose={shareModal.isCancelable ? hide : () => {
      }}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          zIndex: "10000"
        },
        content: {
          border: "none",
          top: "50%",
          left: "50%",
          backgroundColor: "white",
          borderRadius: "20px",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "calc(100% - 20px)",
          maxWidth: "767px",
          padding: 0
        }
      }}
      shouldCloseOnOverlayClick={true} >
      <div className={className}>
        <div className={"p-20"} style={{textAlign: "right"}} onClick={() => {
          hide()
        }}>
          <Icon variant={'icon_close_white'} className={"w-24 h-24"} style={{filter: "brightness(0)"}} />
        </div>
        <div className={styles.text}>{strings.festival_detail.share_text}</div>
        <div className={cx(styles.button_group, "mt-24 mb-48")}>
          <div className={cx(styles.on_button, "w-100 h-100 ml-40")}>
            {strings.festival_detail.on}
          </div>
          <div className={"mr-70 mt-6"}>
            <img src={btnAppstore} className={styles.download_btn} onClick={() => {
              onPressApp()
            }}/>
            <img src={btnPlayStore} className={cx(styles.download_btn, "mt-8")} onClick={() => {
              onPressGoogle()
            }}/>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = state => ({
  appState: state.appState,
});

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators(actions.appActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareModal);
