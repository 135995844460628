import styles from "./Auth.module.scss"
import BasePage from '@/pages/BasePage'
import TopBar from '@/components/topbar/TopBar'
import strings from '@/lang/strings'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames/bind'
import Button from '@/components/button/Button'
import FormControl from '@/components/formControl/FormControl'
import { InputLabel, InputText } from '@/components/input'
import Select from '@/components/select/Select'
import iconGenderManOff from '@/assets/images/icon_gender_man_off@2x.png'
import iconGenderManOn from '@/assets/images/icon_gender_man_on@2x.png'
import iconGenderWomanOff from '@/assets/images/icon_gender_woman_off@2x.png'
import iconGenderWomanOn from '@/assets/images/icon_gender_woman_on@2x.png'
import InputDatePicker from '@/components/input/InputDatePicker'
import { getDateStrFromObj, hasWhiteSpace, sendRequest, validator } from '@/common/common'
import StepBar from '@/components/stepBar/StepBar'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import actions from "../../redux/actions";

const cx = classNames.bind(styles)

const AuthInfoForm = ({appActions, userActions}) => {
  const navigate = useNavigate()

  const [gender, setGender] = useState('')
  const [birthday, setBirthday] = useState(new Date('2000-10-10'))
  const [registrationPath, setRegistrationPath] = useState('')
  const [nickName, setNickname] = useState('')
  const [phone, setPhone] = useState('')
  const [region, setRegion] = useState('')

  const handleBirthdayChange = (value) => {
    setBirthday(value);
  }

  const handleRegistrationPathChange = (e, t) => {
    e.preventDefault()

    setRegistrationPath(t);
  }

  const handleRegionChange = (e, t) => {
    e.preventDefault()

    setRegion(t);
  }

  const handleNext = async () => {
    if (hasWhiteSpace(nickName)) {
      appActions?.showAlertModal({
        text: strings.auth.invalid_nickname
      })
      return
    }

    if (phone?.length && !validator('phone', phone)) {
      appActions?.showAlertModal({
        text: strings.auth.invalid_phone
      })
      return
    }

    const birthYear = getDateStrFromObj(birthday)
    sendRequest(`accounts/user-check/?checkFieldName=nickName&checkValue=${nickName}`, 'GET', null, data => {
      if (phone?.length) {
        sendRequest(`accounts/user-check/?checkFieldName=phone&checkValue=${phone}`, 'GET', null, data => {
          userActions.setUserInfo({
            gender,
            birthYear,
            registrationPath,
            nickName,
            phone,
            region
          })
          navigate('/auth-location-form');
        }, (data, code) => {
          appActions?.showAlertModal({
            text: strings.auth.duplicate_phone
          })
        })
      } else {
        userActions.setUserInfo({
          gender,
          birthYear,
          registrationPath,
          nickName,
          phone,
          region
        })
        navigate('/auth-location-form');
      }
    }, (data, code) => {
      appActions?.showAlertModal({
        text: strings.auth.duplicate_nickname
      })
    })
  }

  const isValid = registrationPath && nickName && region

  return (
    <>
      <BasePage id={"term-agree"} className={cx(styles.root)} pageContent={
        <>
          <TopBar title={strings.auth.custom_info_set} onBack={() => {
            navigate(-1);
          }} />
          <div className={ "mh-16"}>
            <StepBar seek={1} max={4} className={"mt-5"} />
            <div className={cx(styles.tip, 'mt-30')}>{strings.auth.enter_main_info}</div>
            <FormControl className={"mt-30"}>
              <InputLabel htmlFor="type">
                {strings.auth.gender_select}
              </InputLabel>
              <div className={"mt-20"} style={{textAlign: 'center'}}>
                <img src={gender == strings.auth.male ? iconGenderManOn : iconGenderManOff} className={styles.gender} onClick={() => {
                  setGender(strings.auth.male)
                }} />
                <img src={gender == strings.auth.female ? iconGenderWomanOn : iconGenderWomanOff} className={cx(styles.gender, "ml-14")} onClick={() => {
                  setGender(strings.auth.female)
                }} />
              </div>
            </FormControl>
            <FormControl>
              <InputLabel>
                {strings.auth.birthday}
              </InputLabel>
              <InputDatePicker
                id={"birthday"}
                name={"birthday"}
                selected={birthday}
                dateFormat={"yyyy-MM-dd"}
                placeholder={strings.auth.birthday_placeholder}
                onChange={handleBirthdayChange}/>
            </FormControl>
            <FormControl>
              <InputLabel>
                {strings.auth.signup_path}
              </InputLabel>
              <Select onChange={handleRegistrationPathChange} defaultValue={''}>
                <option value={''} key={0} disabled={true}>
                  {strings.auth.signup_path_placeholder}
                </option>
                {
                  strings.auth.signup_path_reasons.map((value, index) => (
                    <option value={value} key={index + 1}>
                      {value}
                    </option>
                  ))
                }
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel>
                {strings.auth.nickname}
              </InputLabel>
              <InputText
                type="text"
                value={nickName}
                onChange={(v) => setNickname(v)}
                inputProps={{
                  placeholder: strings.auth.nickname_placeholder,
                  maxLength: 12,
                }}
              />
            </FormControl>
            <FormControl>
              <InputLabel>
                {strings.auth.contact}
              </InputLabel>
              <InputText
                type="text"
                value={phone}
                onChange={(v) => setPhone(v)}
                inputProps={{
                  placeholder: strings.auth.contact_placeholder,
                  pattern: '^[0-9]*$',
                  maxLength: 11,
                }}
              />
            </FormControl>
            <FormControl>
              <InputLabel>
                {strings.auth.area_of_residence}
              </InputLabel>
              <Select onChange={handleRegionChange} defaultValue={''}>
                <option value={''} key={0} disabled={true}>
                  {strings.auth.region_placeholder}
                </option>
                {
                  strings.auth.regions.map((value, index) => (
                    <option value={value} key={index + 1}>
                      {value}
                    </option>
                  ))
                }
              </Select>
            </FormControl>
            <Button color={isValid ? "orange" : "disable"} fullWidth={true} onClick={isValid && handleNext} className={"mt-5 mb-30"}>
              {strings.next}
            </Button>
          </div>
        </>
      } />
    </>
  )
}

const mapStateToProps = state => ({
  appState: state.appState,
});

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators(actions.appActions, dispatch),
  userActions: bindActionCreators(actions.userActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthInfoForm);
