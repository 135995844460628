import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from '@/App'
import '@/index.scss'
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  <GoogleOAuthProvider clientId="711323682212-t1dena2dt2debjablcv4dknaeh9g4le5.apps.googleusercontent.com">
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </GoogleOAuthProvider>
  // </React.StrictMode>,
)
