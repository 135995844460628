import styles from "./TermAgree.module.scss"
import BasePage from '@/pages/BasePage'
import TopBar from '@/components/topbar/TopBar'
import strings from '@/lang/strings'
import CheckBox from '@/components/button/CheckBox'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames/bind'
import Button from '@/components/button/Button'
import TermView from '@/pages/auth/TermView'
import { sendRequest } from '@/common/common'
import { bindActionCreators } from 'redux'
import actions from '@/redux/actions'
import { connect } from 'react-redux'

const cx = classNames.bind(styles)

const TermAgree = ({appActions, userActions}) => {
  const navigate = useNavigate()

  const [agreeUsage, setAgreeUsage] = useState(false)
  const [agreePrivacy, setAgreePrivacy] = useState(false)
  const [agreeMarketing, setAgreeMarketing] = useState(false)

  let [useRuleChecked, setUseRuleChecked] = useState(false)
  let [privacyChecked, sePrivacyChecked] = useState(false)
  let [marketChecked, seMarketChecked] = useState(false)
  let [allChecked, setAllChecked] = useState(false)
  let [showWarningMsg, setShowWarningMsg] = useState(false)

  const [terms, setTerms] = useState([])
  let [showTerm, setShowTerm] = useState(false)
  let [termContent, setTermContent] = useState({
    title: "",
    content: ""
  })

  useEffect(() => {
    getTerms()
  }, [])

  const getTerms = () => {
    sendRequest('common/terms/', 'GET', null, data => {
      // console.log(JSON.stringify(data))
      setTerms(data)
    })
  }

  const handleStart = () => {
    if (!useRuleChecked || !privacyChecked) {
      setShowWarningMsg(true);
      return;
    }

    userActions?.setUserInfo({
      agreeUsage,
      agreePrivacy,
      agreeMarketing
    })

    // console.log(...user);

    navigate('/auth-info-form');
  }


  const onTermShow = (title, content) => {
    setShowTerm(true);
    setTermContent({title: title, content: content});
  }

  return (
    <>
      <BasePage id={"term-agree"} className={cx(styles.root)} pageContent={
        <>
          <TopBar title={strings.term_agree.agree_terms} onBack={() => {
            navigate(-1);
          }} />
          <div className={cx(styles.container, "mh-16")}>
            <div style={{flex: 1}}>
              <CheckBox iconName={"check_all_off"} onIconName={"check_all_on"} className={"pv-25 fs-15"} text={strings.term_agree.agree_to_all} checked={allChecked} onClick={() => {
                allChecked = !allChecked;
                setAllChecked(allChecked);
                setUseRuleChecked(allChecked);
                sePrivacyChecked(allChecked);
                seMarketChecked(allChecked);

                setAgreeUsage(allChecked)
                setAgreePrivacy(allChecked)
                setAgreeMarketing(allChecked)
              }} />
              <div className={"split"} />
              <div className={"ml-16 mt-10"}>
                <div className={styles.item}>
                  <CheckBox className={"pv-12"} text={strings.term_agree.agree_to_use_rule} checked={useRuleChecked} onClick={() => {
                    useRuleChecked = !useRuleChecked;
                    setUseRuleChecked(useRuleChecked);
                    setAgreeUsage(useRuleChecked);
                    setAllChecked(useRuleChecked && privacyChecked && marketChecked);
                  }} />
                  <span className={"pv-12 ph-10"} onClick={() => {
                    onTermShow(strings.term_agree.use_rule, terms?.filter(item => item?.type === 'USAGE')?.pop()?.content);
                  }}>{strings.term_agree.show}</span>
                </div>
                <div className={styles.item}>
                  <CheckBox className={"pv-12"} text={strings.term_agree.agree_to_privacy_policy} checked={privacyChecked} onClick={() => {
                    privacyChecked = !privacyChecked;
                    sePrivacyChecked(privacyChecked);
                    setAgreePrivacy(privacyChecked)
                    setAllChecked(useRuleChecked && privacyChecked && marketChecked);
                  }} />
                  <span className={"pv-12 ph-10"} onClick={() => {
                    onTermShow(strings.term_agree.privacy_policy, terms?.filter(item => item?.type === 'PRIVACY')?.pop()?.content);
                  }}>{strings.term_agree.show}</span>
                </div>
                <div className={styles.item}>
                  <CheckBox className={"pv-12"} text={strings.term_agree.agree_to_market} checked={marketChecked} onClick={() => {
                    marketChecked = !marketChecked;
                    seMarketChecked(marketChecked);
                    setAgreeMarketing(marketChecked)
                    setAllChecked(useRuleChecked && privacyChecked && marketChecked);
                  }} />
                  <span className={"pv-12 ph-10"} onClick={() => {
                    onTermShow(strings.term_agree.market, terms?.filter(item => item?.type === 'MARKETING')?.pop()?.content);
                  }}>{strings.term_agree.show}</span>
                </div>
              </div>
            </div>
            {
              showWarningMsg &&
              <div className={styles.warning_msg}>
                {strings.term_agree.warning_msg}
              </div>
            }
            <Button color={useRuleChecked && privacyChecked ? "orange" : "disable"} fullWidth={true} onClick={handleStart} className={"mt-5 mb-40"}>
              {strings.start}
            </Button>
          </div>
        </>
      } />
      {showTerm && <TermView title={termContent.title} content={termContent.content} onClose={() => setShowTerm(false)} />}
    </>
  )
}

const mapStateToProps = state => ({
  appState: state.appState,
});

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators(actions.appActions, dispatch),
  userActions: bindActionCreators(actions.userActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TermAgree);
