import styles from "./Login.module.scss"
import classNames from 'classnames/bind'
import strings from '@/lang/strings'
import BasePage from '@/pages/BasePage'
import imgLoginLogo from '@/assets/images/img_login_logo@2x.png'
import { useNavigate } from 'react-router-dom'
import Button from '@/components/button/Button'
import SocialKakao from '@/components/social/SocialKakao'
import SocialNaver from '@/components/social/SocialNaver'
import SocialApple from '@/components/social/SocialApple'
import Icon from '@/components/Icon/Icon'
import { APPLE, GOOGLE, KAKAO, NAVER, sendRequest, storeData, UI_DEV_MODE } from '@/common/common'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import actions from "../../redux/actions";
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { getGoogleUserInfo } from '@/apis'
import React from 'react'

const cx = classNames.bind(styles)

const Login = ({userActions, appActions}) => {
  const navigate = useNavigate()

  const handleAppleLogin = (sid, email) => {
    goNext(sid, email, APPLE)
  }

  // const handleGoogleLogin = (event) => {
  //   goNext("101783069032987871574", "whcho888@gmail.com", GOOGLE);
  // }

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const res = await getGoogleUserInfo({access_token : tokenResponse.access_token})
      console.log('res.sub', res.sub)
      goNext(res.sub, res.email, GOOGLE);
    }
  });

  const handleKakaoLogin = (sid, email) => {
    //navigate('/term-agree')
    console.log('kaka_sid', sid)
    console.log('kaka_email', email)
    goNext(sid, email, KAKAO);
  }

  const handleNaverLogin = (sid, email) => {
    //navigate('/term-agree')
      console.log('naver_sid', sid)
      console.log('naver_email', email)
      goNext(sid, email, NAVER);
      // navigate('/term-agree')
  }

  const goNext = (snsId, email, snsType) => {
    sendRequest(`accounts/social/login/`, 'POST', {
      snsId,
      email,
      snsType
    }, data => {
      userActions.setUserInfo(data?.user)
      storeData({...data?.tokens}).then(() => {
        console.log('login data', data)
        if (data?.user?.isResign) {

        } else if(data?.user?.agreeUsage) {
          navigate('/home')
        } else {
          navigate('/term-agree')
        }
      })
    }, (data, code) => {
      appActions?.showAlertModal({
        text: `${data?.msg}`
      })
    })
  }

  return (
    <>
      <BasePage id={"login"} className={cx(styles.root)} pageContent={
        <>
          <img src={imgLoginLogo} alt='logo' className={styles.logo}/>
          <div className={cx(styles.close, "p-10")} onClick={() => navigate(-1)}>
            <Icon variant={'icon_close_black'} className={"w-24 h-24"} />
          </div>
          <SocialKakao
            onSuccess={handleKakaoLogin}/>
          <SocialNaver
            onSuccess={handleNaverLogin}/>
          {/*<Button color="yellow" fullWidth={true} onClick={handleKakaoLogin} className={"mt-50"}>
            <Icon variant="icon_sns3" className={"mr-7 w-22 h-23"} />
            {strings.login.signin_with_kakao}
          </Button>*/}
          {/*<Button color="green" fullWidth={true} onClick={handleNaverLogin} className={"mt-11"}>
            <Icon variant="icon_sns4" className={"mr-7 w-22 h-23"} />
            {strings.login.signin_with_naver}
          </Button>*/}
          <SocialApple
            onSuccess={handleAppleLogin}/>
          {/*<Button color="grey" fullWidth={true} onClick={handleAppleLogin} className={"mt-11"}>
            <Icon variant="icon_sns2" className={"mr-7 w-22 h-23"} />
            {strings.login.signin_with_apple}
          </Button>*/}
          <Button color="grey-outline" fullWidth={true} onClick={handleGoogleLogin} className={"mt-11"}>
            <Icon variant="icon_sns1" className={"mr-7 w-21 h-21"} />
            {strings.login.signin_with_google}
          </Button>
        </>
      } />
    </>
  )
}

const mapStateToProps = state => ({
  appState: state.appState,
});

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators(actions.appActions, dispatch),
  userActions: bindActionCreators(actions.userActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
