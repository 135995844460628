import styles from './Festival.module.scss'
import BasePage from '@/pages/BasePage'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames/bind'
import Header from '@/components/header/Header'
import Icon from '@/components/Icon/Icon'
import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import actions from '../../redux/actions'
import { connect } from 'react-redux'
import Footer from '@/components/footer/Footer'
import strings from '@/lang/strings'
import { getDateString, getDistanceFromLatLonInKm, sendRequest } from '@/common/common'
import iconCategoryMusic from '@/assets/images/icon_category_music@2x.png'
import iconCategoryCulture from '@/assets/images/icon_category_culture@2x.png'
import iconCategoryFood from '@/assets/images/icon_category_food@2x.png'
import iconCategoryConcert from '@/assets/images/icon_category_concert@2x.png'
import iconCategoryEtc from '@/assets/images/icon_category_etc@2x.png'
import FestivalCell from '@/components/home/FestivalCell'
import imgSub02Top from '@/assets/images/img_sub_02_top@2x.png'

const cx = classNames.bind(styles)

const Section = ({icon, title, contents, data, cellWidth, festivalActions, navigate, userLocation, onClickAll = () => {}}) => {
  return (
    <div>
      <div className={styles.section_header}>
        <div className={styles.section_title}>
          <img src={icon} className={"w-16 h-16 mr-6"} />
          <div className={styles.section_title}>{title}</div>
        </div>
        <div onClick={onClickAll}>
          <div className={styles.section_all}>{strings.festival.view_all}</div>
        </div>
      </div>
      <div>
        <div className={cx(styles.festival_wrapper, "mt-13")}>
          {
            data.slice(0, 4).map((item, index) => {
              return (
                <FestivalCell
                  key={index}
                  title={item.title}
                  date={getDateString(new Date(item.startDateTime)) + ' ~ ' + getDateString(new Date(item.endDateTime)) }
                  location={item?.address}
                  distance={getDistanceFromLatLonInKm(item?.lat, item?.lon, userLocation.latitude, userLocation.longitude) + 'km'}
                  isLiked={item?.isLike}
                  item={item}
                  showInfoOuter={true}
                  width={cellWidth}
                  style={{marginRight: (index % 4 !=3) && '8px'}}
                  onClick={() => {
                    festivalActions.setFestival(item)
                    navigate('/festival/' + item.id)
                  }}/>
              )
            })
          }
        </div>
      </div>
    </div>
  );
};

const Festival = ({appState: {spinner}, festival: {musics, cultures, foods, concerts, etcs}, user: {userLocation}, appActions, userActions,
                    festivalActions, eventActions}) => {
  const navigate = useNavigate()

  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const [cellWidth, setCellWidth] = React.useState(0);

  useEffect(() => {
    getData()
    updateCellWidth(screenWidth);
    const handleResizeWindow = () => updateCellWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, [])

  const updateCellWidth = (screenWidth) => {
    screenWidth = screenWidth > 1320 ? 1320 : screenWidth
    setScreenWidth(screenWidth)
    setCellWidth((screenWidth - (8 * 3 + 12 * 2) * (screenWidth > 767 ? 1.7 : 1)) / 4)
  }

  const getData = () => {
    sendRequest('festival/festival/?festivalType=Music&size=10', 'GET', null,  data => {
      festivalActions?.setMusics(data)
    })

    sendRequest('festival/festival/?festivalType=Culture&size=10', 'GET', null,  data => {
      festivalActions?.setCultures(data)
    })

    sendRequest('festival/festival/?festivalType=Food&size=10', 'GET', null,  data => {
      festivalActions?.setFoods(data)
    })

    sendRequest('festival/festival/?festivalType=Concert&size=10', 'GET', null,  data => {
      festivalActions?.setConcerts(data)
    })

    sendRequest('festival/festival/?festivalType=' + strings.festival.etc + '&size=10', 'GET', null,  data => {
      festivalActions?.setEtcs(data)
    })
  }

  return (
    <>
      <BasePage id={'search'} className={cx(styles.root)} pageContent={
        <>
          <Header
            styles={{position: 'absolute'}}
            hasBack
            backIcon={'icon_back_white'}
            hasBackground={false}
            rightComp={
              <div style={{display: 'flex'}}>
                <div className={"w-30 h-40 center"} onClick={() => navigate('/search')}>
                  <Icon variant="search" className={"w-16 h-16"} />
                </div>
                <div className={"w-30 h-40 center mr-5"} onClick={() => appActions.showRightModal()}>
                  <Icon variant="icon_option" className={"w-16 h-16"} />
                </div>
              </div>
            }
          />
          <div className={styles.top}>
            <img src={imgSub02Top} className={"w100"} />
            <div className={styles.contents}>
              <div className={styles.title}>
                {strings.festival.festival_info}
              </div>
              <div className={cx(styles.desc, "mt-14")}>
                {strings.festival.festival_info_desc}
              </div>
            </div>
          </div>
          <div style={{flex: 1}}>
            <Section
              icon={iconCategoryMusic}
              title={strings.festival.music}
              onClickAll={() => {
                festivalActions.setFestivalType('Music')
                navigate('/festival-by-type/' + 'Music');
              }}
              data={musics}
              cellWidth={cellWidth}
              userLocation={userLocation}
              navigate={navigate}
              festivalActions={festivalActions}
            />
            <Section
              icon={iconCategoryCulture}
              title={strings.festival.culture}
              onClickAll={() => {
                festivalActions.setFestivalType('Culture')
                navigate('/festival-by-type/' + 'Culture');
              }}
              data={cultures}
              cellWidth={cellWidth}
              userLocation={userLocation}
              navigate={navigate}
              festivalActions={festivalActions}
            />
            <Section
              icon={iconCategoryFood}
              title={strings.festival.food}
              onClickAll={() => {
                festivalActions.setFestivalType('Food')
                navigate('/festival-by-type/' + 'Food');
              }}
              data={foods}
              cellWidth={cellWidth}
              userLocation={userLocation}
              navigate={navigate}
              festivalActions={festivalActions}
            />
            <Section
              icon={iconCategoryConcert}
              title={strings.festival.concert}
              onClickAll={() => {
                festivalActions.setFestivalType('Concert')
                navigate('/festival-by-type/' + 'Concert');
              }}
              data={concerts}
              cellWidth={cellWidth}
              userLocation={userLocation}
              navigate={navigate}
              festivalActions={festivalActions}
            />
            <Section
              icon={iconCategoryEtc}
              title={strings.festival.etc}
              onClickAll={() => {
                festivalActions.setFestivalType(strings.festival.etc)
                navigate('/festival-by-type/' + strings.festival.etc);
              }}
              data={etcs}
              cellWidth={cellWidth}
              userLocation={userLocation}
              navigate={navigate}
              festivalActions={festivalActions}
            />
            <div className={"mt-45"} />
          </div>
          <Footer backgroundColor={"#f3f3f3"} />
        </>
      } />
    </>
  )
}

const mapStateToProps = state => ({
  appState: state.appState,
  user: state.user,
  festival: state.festival
});

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators(actions.appActions, dispatch),
  userActions: bindActionCreators(actions.userActions, dispatch),
  festivalActions: bindActionCreators(actions.festivalActions, dispatch),
  eventActions: bindActionCreators(actions.eventActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Festival);
