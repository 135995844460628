import styles from './FestivalByType.module.scss'
import BasePage from '@/pages/BasePage'
import { useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames/bind'
import Header from '@/components/header/Header'
import Icon from '@/components/Icon/Icon'
import React, { useEffect, useRef, useState } from 'react'
import { bindActionCreators } from 'redux'
import actions from '../../redux/actions'
import { connect } from 'react-redux'
import Footer from '@/components/footer/Footer'
import strings from '@/lang/strings'
import { getDateString, getDistanceFromLatLonInKm, isCloseToBottom, sendRequest } from '@/common/common'
import iconCategoryMusic from '@/assets/images/icon_category_music@2x.png'
import iconCategoryCulture from '@/assets/images/icon_category_culture@2x.png'
import iconCategoryFood from '@/assets/images/icon_category_food@2x.png'
import iconCategoryConcert from '@/assets/images/icon_category_concert@2x.png'
import iconCategoryEtc from '@/assets/images/icon_category_etc@2x.png'
import FestivalCell from '@/components/home/FestivalCell'
import imgSub02Top from '@/assets/images/img_sub_02_top@2x.png'

const cx = classNames.bind(styles)

const FestivalByType = ({appState: {spinner}, festival: {musics, cultures, foods, concerts, etcs}, user: {userLocation}, appActions, userActions,
                    festivalActions, eventActions}) => {
  const navigate = useNavigate()
  const params = useParams()

  const footer = useRef(null)
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const [cellWidth, setCellWidth] = React.useState(0);

  const [page, setPage] = useState(1)
  const [isLoading, setIsLoading] = useState(1)
  const [isLast, setIsLast] = useState(false)
  const [pageData, setPageData] = useState({})
  const [festivals, setFestivals] = useState([])

  const types = {
    Music: {
      icon : iconCategoryMusic,
      label : strings.festival.music,
      dimension: {
        width: 25,
        height: 24,
      },
    },
    Culture: {
      icon : iconCategoryCulture,
      label : strings.festival.culture,
      dimension: {
        width: 25,
        height: 24,
      },
    },
    Food: {
      icon : iconCategoryFood,
      label : strings.festival.food,
      dimension: {
        width: 25,
        height: 24,
      },
    },
    Concert: {
      icon : iconCategoryConcert,
      label : strings.festival.concert,
      dimension: {
        width: 25,
        height: 24,
      },
    },
    Etc: {
      icon : iconCategoryEtc,
      label : strings.festival.etc,
      dimension: {
        width: 25,
        height: 24,
      },
    },
  }

  useEffect(() => {
    getData()
    updateCellWidth(screenWidth);
    const handleResizeWindow = () => updateCellWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, [])

  const updateCellWidth = (screenWidth) => {
    screenWidth = screenWidth > 1320 ? 1320 : screenWidth
    setScreenWidth(screenWidth)
    setCellWidth((screenWidth - (8 * 3 + 12 * 2) * (screenWidth > 767 ? 1.7 : 1)) / 4)
  }

  const getData = () => {
    sendRequest(`festival/festival/?festivalType=${params.type}`, 'GET', null,  data => {
      // data.push(data[0])
      // data.push(data[0])
      // data.push(data[0])
      // data.push(data[0])
      // data.push(data[0])
      // data.push(data[0])
      // data.push(data[0])
      // data.push(data[0])
      // data.push(data[0])
      // data.push(data[0])
      // data.push(data[0])
      // data.push(data[0])
      setFestivals(data)
    })
  }

  const onScroll = (e) => {
    console.log('scrolling')
  }

  const isCloseToBottom = ({clientHeight, scrollTop, scrollHeight}) => {
    const paddingToBottom = 50;

    console.log(clientHeight, scrollTop, scrollHeight, document.getElementById('footer'));
    // console.log(layoutMeasurement.height + contentOffset.y, contentSize.height - paddingToBottom)
    return (
      clientHeight + scrollTop >= scrollHeight - paddingToBottom
    );
  }

  return (
    <>
      <BasePage id={'search'} className={cx(styles.root)} pageContent={
        <div style={{overflowY: "auto"}}
          onScroll={({nativeEvent}) => {
            console.log(isCloseToBottom(nativeEvent.target));
            // if (isCloseToBottom(nativeEvent) && !isLoading && !isLast) {
            //   setIsLoading(true);
            //   setPage(parseInt(page) + 1);
            // }
          }}>
          <Header
            styles={{position: 'absolute'}}
            hasBack
            backIcon={'icon_back_white'}
            hasBackground={false}
            rightComp={
              <div style={{display: 'flex'}}>
                <div className={"w-30 h-40 center"} onClick={() => navigate('/search')}>
                  <Icon variant="search" className={"w-16 h-16"} />
                </div>
                <div className={"w-30 h-40 center mr-5"} onClick={() => appActions.showRightModal()}>
                  <Icon variant="icon_option" className={"w-16 h-16"} />
                </div>
              </div>
            }
          />
          <div className={styles.top}>
            <img src={imgSub02Top} className={"w100"} />
            <div className={styles.contents}>
              <div className={styles.title}>
                {strings.festival.festival_info}
              </div>
              <div className={cx(styles.desc, "mt-14")}>
                {strings.festival.festival_info_desc}
              </div>
            </div>
          </div>
          <div style={{flex: 1}}>
            <div className={styles.section_header}>
              <div className={styles.section_title}>
                <img src={types[params.type] ? types[params.type].icon : ''} className={"w-16 h-16 mr-6"} />
                <div className={styles.section_title}>{types[params.type] ? types[params.type].label : ''}</div>
              </div>
            </div>
            <div className={cx(styles.festival_wrapper, "mt-13")}>
              {
                festivals.map((item, index) => {
                  return (
                    <FestivalCell
                      key={index}
                      title={item.title}
                      date={getDateString(new Date(item.startDateTime)) + ' ~ ' + getDateString(new Date(item.endDateTime)) }
                      location={item?.address}
                      distance={getDistanceFromLatLonInKm(item?.lat, item?.lon, userLocation.latitude, userLocation.longitude) + 'km'}
                      isLiked={item?.isLike}
                      item={item}
                      showInfoOuter={true}
                      width={cellWidth}
                      style={{marginRight: (index % 4 !=3) && '8px'}}
                      onClick={() => {
                        festivalActions.setFestival(item)
                        navigate('/festival/' + item.id)
                      }}/>
                  )
                })
              }
            </div>
            <div className={"mt-45"} />
          </div>
          <Footer
            backgroundColor={"#f3f3f3"}
          />
        </div>
      } />
    </>
  )
}

const mapStateToProps = state => ({
  appState: state.appState,
  user: state.user,
  festival: state.festival
});

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators(actions.appActions, dispatch),
  userActions: bindActionCreators(actions.userActions, dispatch),
  festivalActions: bindActionCreators(actions.festivalActions, dispatch),
  eventActions: bindActionCreators(actions.eventActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FestivalByType);
