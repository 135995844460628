import React, { useEffect } from 'react'
import {bindActionCreators} from 'redux';
import actions from '../../redux/actions';
import {connect} from 'react-redux';
import Drawer from 'rc-drawer'
import 'rc-drawer/assets/index.css'
import Icon from '@/components/Icon/Icon'
import classNames from 'classnames/bind'
import styles from './RightModal.module.scss'
import strings from '@/lang/strings'
import { useNavigate } from 'react-router-dom'
import { removeData } from '@/common/common'

const cx = classNames.bind(styles)

const RightModal = ({user: {user}, appState: {rightModal}, appActions, userActions}) => {
  const className = cx(styles.root)

  const navigate = useNavigate()
  const [width, setWidth] = React.useState(200);

  useEffect(() => {
    updateWidth(window.innerWidth);
    const handleResizeWindow = () => updateWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, [])

  const updateWidth = (screenWidth) => {
    screenWidth = screenWidth > 1320 ? 1320 : screenWidth
    setWidth(250 * (screenWidth > 767 ? 1.7 : 1))
  }

  const hide = onPress => {
    appActions.hideRightModal();
  };

  const logout = () => {
    removeData(['accessToken']).then(data => {
      userActions.deleteUserInfo()
      hide()
      navigate('/home', {replace: true})
      // navigation.dispatch(
      //   CommonActions.reset({
      //     index: 0,
      //     routes: [
      //       {name: 'home'},
      //     ],
      //   }),
      // );
    })
  }

  const handleLogin = () => {
    if (user?.id && user?.firstSetup) {
      appActions.showAlertModal({
        text: strings.right_bar.logout_desc,
        leftBtnText: strings.cancel,
        rightBtnText: strings.right_bar.logout,
        onPressRight: logout,
      });
      return;
    }
    hide();
    navigate('/login');
  }

  const handleGoto = () => {
    appActions?.showShareModal()
  }

  const menus = [
    {
      label: strings.right_bar.menu_items[0],
      icon: "icon_onfriends",
      dimension: {
        width: 12,
        height: 12,
      },
      onClick: () => {
        navigate('/about')
      },
    },
    {
      label: strings.right_bar.menu_items[1],
      icon: "icon_festival",
      dimension: {
        width: 12,
        height: 12,
      },
      onClick: () => {
        navigate('/festival')
      },
    },
    {
      label: strings.right_bar.menu_items[2],
      icon: "icon_event",
      dimension: {
        width: 12,
        height: 12,
      },
      onClick: () => {
        navigate('/event')
      },
    },
    {
      label: strings.right_bar.menu_items[3],
      icon: "icon_inquiry",
      dimension: {
        width: 12,
        height: 12,
      },
      onClick: () => {
        navigate('/inquiry')
      },
    },
    {
      label: strings.right_bar.menu_items[4],
      icon: "icon_notice",
      dimension: {
        width: 12,
        height: 12,
      },
      onClick: () => {
        navigate('/notice/0')
      },
    },
  ];

  const handleFacebook = () => {
    window.open('https://m.facebook.com/festivalon.net?wtsid=rdr_01WGdFMDCEXJ4PRWi', '_blank');
  }

  const handleYoutube = () => {
    window.open('https://youtube.com/@festivalon?si=eBU1jFHifj6EwOwC', '_blank');
  }

  return (
    <Drawer
      className={className}
      open={rightModal.isVisible}
      placement={'right'}
      width={width}
      maskClosable={false}
      children={
        <>
          <div className={"h100"}>
            <div className={"p-10"} onClick={hide}>
              <Icon variant={'icon_close_white'} className={"w-12 h-12"} />
            </div>
          </div>
          <div className={styles.container}>
            <div className={styles.login_wrapper}>
              <div className={styles.login_btn} onClick={handleLogin}>
                {
                  user?.id && user?.firstSetup &&
                    <>
                      <img className={cx(styles.profile_image, "mr-5")} src={user?.image}/>
                      <div className={"mr-5"}>{user?.nickName} {strings.right_bar.sir}</div>
                    </>
                }
                <>
                  <div>{user?.id && user?.firstSetup ? strings.right_bar.logout : strings.right_bar.login}</div>
                  <Icon variant={'icon_arrow_right'} className={"w-12 h-12"} />
                </>
              </div>
              {
                user?.id && user?.firstSetup &&
                  <div className={styles.login_btn} onClick={handleGoto}>
                    <div>{strings.right_bar.go_to}</div>
                    <Icon variant={'icon_arrow_right'} className={"w-12 h-12"} />
                  </div>
              }
            </div>
            <div className={cx(styles.menu_wrapper, "pv-6")}>
              {menus.map((i, idx) => (
                <div
                  key={i.label}
                  className={styles.menu_item} onClick={() => {
                    hide()
                    i.onClick()
                  }}>
                  <div className={styles.menu_icon}>
                    <Icon variant={i.icon} className={"w-" + i.dimension.width + " h-" + i.dimension.height} />
                  </div>
                  <div className={cx(styles.menu_text, "ml-7")}>{i.label}</div>
                </div>
              ))}
            </div>
            <div className={cx(styles.sns_wrapper, "mt-8")}>
              <div onClick={handleFacebook}>
                <Icon variant={'icon_f'} className={"w-12 h-12"} />
              </div>
              <div className={"ml-4"} onClick={handleYoutube}>
                <Icon variant={'icon_video'} className={"w-12 h-12"} />
              </div>
            </div>
          </div>
        </>
      }
      onClose={() => {
        hide();
      }}
    />
  );
};

const mapStateToProps = state => ({
  appState: state.appState,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators(actions.appActions, dispatch),
  userActions: bindActionCreators(actions.userActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RightModal);
