import styles from './Select.module.scss'
import classNames from 'classnames/bind'
import React, { useEffect, useState } from 'react'
import Icon from '../Icon/Icon'

const cx = classNames.bind(styles)

/**
 *
 * @param {Object} props
 * @param {string} [props.className]
 * @param {any} [props.value]
 * @param {Object} [props.inputProps]
 * @returns {JSX.Element}
 */
const Select = (props) => {
  const { children, className: rootClassName, inputProps, defaultValue, iconVariant = 'icon_down_arrow', onChange } = props
  const className = cx(styles.root, rootClassName)
  const [selectedValue, setSelectedValue] = useState('')
  const [empty, setEmpty] = useState(true)
  const childrenArray = React.Children.toArray(children)

  useEffect(() => {
    setEmpty(['', null, -1].indexOf(defaultValue) > -1)
    setSelectedValue(defaultValue)
  }, [defaultValue])

  const handleChange = (event) => {
    const index = childrenArray.map((child) => child.props.value).indexOf(event.target.value)

    if (index === -1) return

    const child = childrenArray[index]
    setEmpty(false);
    setSelectedValue(child.props.value)

    if (onChange) {
      onChange(event, event.target.value)
    }
  }

  return (
    <>
      <div className={className}>
        <select
          className={cx(styles.select, empty && styles.empty)}
          onChange={handleChange}
          value={selectedValue}
          {...inputProps}
        >
          {children}
        </select>

        <Icon variant={iconVariant} width="24px" height="24px" />
      </div>
    </>
  )
}

export default Select
