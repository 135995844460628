import styles from './FormControl.module.scss'
import classNames from 'classnames/bind'
import React from 'react'

const cx = classNames.bind(styles)

/**
 *
 * @param {Object} props
 * @param {string} [props.className]
 * @returns {JSX.Element}
 */
const FormControl = (props) => {
  const { children, className: rootClassName } = props
  const className = cx(styles.root, rootClassName)

  return (
    <>
      {/* <div className={className}>{React.cloneElement(children, { required })}</div> */}
      <div className={className}>{children}</div>
    </>
  )
}

export default FormControl
