import styles from './About.module.scss'
import BasePage from '@/pages/BasePage'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames/bind'
import Header from '@/components/header/Header'
import Icon from '@/components/Icon/Icon'
import React from 'react'
import strings from '@/lang/strings'
import { bindActionCreators } from 'redux'
import actions from "../../redux/actions";
import { connect } from 'react-redux'
import imgMainTop from '@/assets/images/img_main_top@2x.png'
import sub01TopOnFriends from '@/assets/images/sub_01_top_onfriends.png'
import sub01Screen1 from '@/assets/images/sub_01_screen_1@2x.png'
import sub01Screen2 from '@/assets/images/sub_01_screen_2@2x.png'
import sub01Screen3 from '@/assets/images/sub_01_screen_3@2x.png'
import sub01Screen4 from '@/assets/images/sub_01_screen_4@2x.png'
import sub01Screen5 from '@/assets/images/sub_01_screen_5@2x.png'
import Footer from '@/components/footer/Footer'

const cx = classNames.bind(styles)

const About = ({appState: {}, user: {user}, appActions}) => {
  const navigate = useNavigate()

  return (
    <>
      <BasePage id={'home'} className={cx(styles.root)} pageContent={
        <>
          <Header
            styles={{position: 'absolute'}}
            hasBack
            backIcon={'icon_back_white'}
            title={strings.home.on_friends}
            color={'white'}
            hasBackground={false}
            rightComp={
              <div style={{display: 'flex'}}>
                <div className={"w-30 h-40 center"} onClick={() => navigate('/search')}>
                  <Icon variant="search" className={"w-16 h-16"} />
                </div>
                <div className={"w-30 h-40 center mr-5"} onClick={() => appActions.showRightModal()}>
                  <Icon variant="icon_option" className={"w-16 h-16"} />
                </div>
              </div>
            }
          />
          <div className={styles.top}>
            <img style={{width: '100%'}} src={imgMainTop} />
            <div className={styles.contents}>
              <div className={styles.tip1}>
                {strings.about.tip1}
              </div>
              <img src={sub01TopOnFriends} className={"w-65 h-55 mt-5"}/>
            </div>
          </div>
          <div className={cx(styles.tip2, "mt-53")}>
            {strings.about.tip2.slice(0, 7)}
            <span>{strings.about.tip2.slice(7)}</span>
          </div>
          <div className={cx(styles.step_title, "mh-32 mt-27")} style={{textAlign: "center"}}>{strings.about.step1_title}</div>
          <div className={cx(styles.step_desc, "mh-36 mt-18")}>{strings.about.step1_desc}</div>
          <div className={"mt-28"}>
            <div className={cx(styles.screenshot_text_wrapper, "pl-35")} style={{paddingRight: "179px"}}>
              <div className={cx(styles.line)} />
              <div className={cx(styles.screenshot_title, "mt-15")}>{strings.about.screenshot1_title}</div>
              <div className={cx(styles.screenshot_desc, "mt-20")}>{strings.about.screenshot1_desc}</div>
            </div>
            <img src={sub01Screen1} className={styles.screenshot_festival}/>
          </div>
          <div className={styles.clear_both} />
          <div className={"mt-52"} style={{display: 'flex'}}>
            <img src={sub01Screen2} className={cx(styles.screenshot_meeting, "ml-35")}/>
            <div className={cx(styles.screenshot_text_wrapper, "pl-25")} style={{height: "auto", backgroundColor: "transparent"}}>
              <div className={cx(styles.line)} />
              <div className={cx(styles.screenshot_title, "mt-15")}>{strings.about.screenshot2_title}</div>
              <div className={cx(styles.screenshot_desc, "mt-20")}>{strings.about.screenshot2_desc}</div>
            </div>
          </div>
          <div className={cx(styles.step_title, "mh-20 mt-75")} style={{textAlign: "center"}}>{strings.about.step2_title}</div>
          <div className={cx(styles.step_desc, "mh-20 mt-18")}>{strings.about.step2_desc}</div>
          <div className={"mt-28"}>
            <div className={cx(styles.screenshot_text_wrapper, "pl-35")} style={{paddingRight: "184px"}}>
              <div className={cx(styles.line)} />
              <div className={cx(styles.screenshot_title, "mt-15")}>{strings.about.screenshot3_title}</div>
              <div className={cx(styles.screenshot_desc, "mt-20")}>{strings.about.screenshot3_desc}</div>
            </div>
            <img src={sub01Screen3} className={styles.screenshot_live_map}/>
          </div>
          <div className={styles.clear_both} />
          <div className={"mt-52"} style={{display: 'flex'}}>
            <img src={sub01Screen4} className={cx(styles.screenshot_site_map, "ml-35")}/>
            <div className={cx(styles.screenshot_text_wrapper, "pl-25")} style={{height: "auto", backgroundColor: "transparent"}}>
              <div className={cx(styles.line)} />
              <div className={cx(styles.screenshot_title, "mt-15")}>{strings.about.screenshot4_title}</div>
              <div className={cx(styles.screenshot_desc, "mt-20")}>{strings.about.screenshot4_desc}</div>
            </div>
          </div>
          <div className={cx(styles.step_title, "mh-20 mt-75")} style={{textAlign: "center"}}>{strings.about.step3_title}</div>
          <div className={cx(styles.step_desc, "mh-20 mt-18")}>{strings.about.step3_desc}</div>
          <div className={"mt-28"}>
            <div className={cx(styles.screenshot_text_wrapper, "pl-35")} style={{paddingRight: "185px"}}>
              <div className={cx(styles.line)} />
              <div className={cx(styles.screenshot_title, "mt-15")}>{strings.about.screenshot5_title}</div>
              <div className={cx(styles.screenshot_desc, "mt-20")}>{strings.about.screenshot5_desc}</div>
            </div>
            <img src={sub01Screen5} className={cx(styles.screenshot_interpreter, "mb-74")}/>
          </div>
          <div className={styles.clear_both} />
          <Footer backgroundColor={"#f3f3f3"} />
        </>
      } />
    </>
  )
}

const mapStateToProps = state => ({
  appState: state.appState,
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators(actions.appActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(About);
