import styles from './TopBar.module.scss'
import classNames from 'classnames/bind'
import Icon from '@/components/Icon/Icon'

const cx = classNames.bind(styles)

/**
 *
 * @param {Object} props
 * @param {string} [props.className]
 * @returns {JSX.Element}
 */
const TopBar = (props) => {
  const {
    title,
    className: rootClassName,
    onBack,
  } = props

  const className = cx(styles.root)

  const handleBack = (event) => {
    if (onBack) {
      onBack(event)
    }
  }

  return (
    <>
      <div
        className={className}>
        <div className={"w-45 h-45 center"} onClick={handleBack}>
          <Icon variant="icon_back_arrow" className={"w-20 h-20"} />
        </div>
        <div className={cx(styles.title, "center")}>
          {title}
        </div>
      </div>
    </>
  )
}

export default TopBar
