import _styles from './Header.module.scss'
import classNames from 'classnames/bind'
import React, {useEffect} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {bindActionCreators} from 'redux';
import actions from '../../redux/actions';
import topLogo from '@/assets/images/top_logo@2x.png'
import Icon from '@/components/Icon/Icon'
import { useNavigate } from 'react-router-dom'

const cx = classNames.bind(_styles)

const Header = ({
  user: {user},
  title,
  hasBack,
  hasShare,
  shareType,
  hasBookmark,
  isBookMark,
  onBookMark = () => {},
  shop = {},
  hasBorder = false,
  centerComp = null,
  whiteIcon = false,
  rightComp = null,
  backgroundColor = '#fff',
  color = 'rgba(34, 34, 34, 1)',
  hasBackground = true,
  backIcon= 'icon_back_arrow',
  hasLogo=false,
  styles = {},
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const state = useSelector(state => state.user);

  const className = cx(_styles.root)

  const titleStyle = {
    letterSpacing: -0.5,
    color,
  };

  const onShare = async () => {

  };

  const leftComponent = () => {
    if (hasLogo) {
      return (
        <div>
          <img src={topLogo} className={_styles.logo} />
        </div>
      )
    }
    else if (hasBack) {
      return (
        <div className={_styles.back_wrapper}>
          <div className={"w-40 h-40 center"} onClick={() => navigate(-1)}>
            <Icon variant={backIcon} className={"w-20 h-20"} />
          </div>
        </div>
      );
    } else {
      return <div className={_styles.back_wrapper} />
    }
  };

  const centerComponent = () => {
    if (centerComp) {
      return (
        <div className={_styles.center_container}>{centerComp}</div>
      );
    } else if (title?.length) {
      return (
        <div className={_styles.title_wrapper}>
          <div className={_styles.title} style={color && {color: color}}>
            {title}
          </div>
        </div>
      );
    } else if (hasLogo) {
      return <div style={{flex: 1}} />;
    } else if (!title) {
      return <div style={{flex: 5}} />;
    }
  };

  const rightComponent = () => {
    if (rightComp) {
      return <div className={_styles.right_container}>{rightComp}</div>;
    } else {
      return (
        <div
          className={_styles.right_container2}
          style={(hasShare || hasBookmark) && !centerComp && { marginRight: 0 }}>
          {hasShare && (
            <div className={"w-40 h-40 center mr-10"} onClick={onShare}>
              <Icon variant="icon_share" className={"w-28 h-28"} />
            </div>
          )}
          {hasBookmark && (
            <div className={"w-40 h-40 center mr-10"} onClick={onBookMark}>
              <Icon variant="icon_share" className={"w-28 h-28"} />
            </div>
          )}
        </div>
      );
    }
  };

  useEffect(() => {}, [isBookMark]);

  return (
    <div className={cx(className)} style={{backgroundColor: hasBackground && backgroundColor, borderBottomWidth: hasBorder && 1, ...styles}}>
      {leftComponent()}
      {centerComponent()}
      {rightComponent()}
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.user,
  appState: state.appState,
});

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators(actions.appActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
