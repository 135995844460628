import { useController } from 'react-hook-form'

import styles from './InputDatePicker.module.scss'
import classNames from 'classnames/bind'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import Icon from '@/components/Icon/Icon'
import React, { useState } from 'react'

const cx = classNames.bind(styles)

/**
 *
 * @param {Object} props
 * @param {string} [props.className]
 * @param {any} [props.value]
 * @param {Object} [props.inputProps]
 * @returns {JSX.Element}
 */
const InputDatePicker = (props) => {
  const { id, selected, dateFormat, placeholder, className: rootClassName, onChange } = props
  const className = cx(styles.root, rootClassName)

  return (
    <>
      <div className={className}>
          <DatePicker
            id={id}
            dateFormat={dateFormat}
            onChange={onChange}
            placeholderText={placeholder}
            showYearDropdown={true}
            showMonthDropdown={true}
            selected={selected}
          />
          <Icon width="24px" height="24px" />
      </div>
    </>
  )
}

export default InputDatePicker
