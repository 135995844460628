import styles from './FestivalCell.module.scss'
import classNames from 'classnames/bind'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from "react";
import { sendRequest } from '@/common/common'
import { bindActionCreators } from "redux";
import actions from "../../redux/actions";
import { connect } from "react-redux";
import Icon from '@/components/Icon/Icon'
import strings from '@/lang/strings'

const cx = classNames.bind(styles)

const FestivalCell = ({
  style: rootStyle,
  aspectRatio = 0.82,
  className: rootClassName,
  user: {user},
  festival: {festival},
  title,
  date,
  location,
  distance,
  isLiked,
  item,
  width = null,
  showInfoOuter = false,
  appActions,
  onClick=null
}) => {

  const navigate = useNavigate()
  const className = cx(styles.root, rootClassName)

  const mainImage = item?.images[0]

  const like = (e) => {
    e.stopPropagation()
    if (!user?.id) {
      appActions?.showAlertModal({
        text: strings.festival.go_to_login,
        onPressLeft: () => navigate('/login')
      })
      return
    }

    sendRequest('log/like/', 'POST', {
      festival: item?.id
    }, data => {
      setIsLikeLocal(!isLikeLocal)
    })
  }

  const [isLikeLocal, setIsLikeLocal] = useState(isLiked)

  useEffect(() => {
    if (festival?.id === item?.id) {
      setIsLikeLocal(festival?.isLike)
    }
  }, [festival?.isLike])

  return (
    <div
      className={className}
      style={rootStyle}
      onClick={() => {
        onClick && onClick()
        // festivalActions.setFestival(item)
        // navigate('FestivalDetail');
      }}
    >
      <div className={styles.image_background} style={{width: width, aspectRatio: aspectRatio}} />
      <div className={styles.image_wrapper} style={{width: width, aspectRatio: aspectRatio}}>
        <img
          width={"100%"}
          height={"100%"}
          src={mainImage}
          loading="lazy"
        />
      </div>
      {!showInfoOuter && (
        <div className={styles.inner_info_container} style={{width: width}}>
          <div className={styles.inner_title}>{title}</div>
          <div className={"mb-12"}>
            <div className={styles.inner_desc}>{date}</div>
            <div className={styles.inner_desc}>
              {location}
            </div>
          </div>
        </div>
      )}
      {showInfoOuter && (
        <div className={styles.outer_info_container} style={{width: width}}>
          <div className={styles.outer_title}>{title}</div>
          <div>
            <div className={styles.outer_desc}>{date}</div>
            <div className={styles.outer_desc}>
              {location}
            </div>
          </div>
        </div>
      )}
      <div
        className={styles.like_icon}
        onClick={like}
      >
        <Icon variant={isLikeLocal ? "icon_heart_on" : "icon_heart_off"} className={"w-16 h-16 m-6"} />
      </div>
    </div>
  );
};


const mapStateToProps = state => ({
  appState: state.appState,
  user: state.user,
  festival: state.festival,
});

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators(actions.appActions, dispatch),
  userActions: bindActionCreators(actions.userActions, dispatch),
  festivalActions: bindActionCreators(actions.festivalActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FestivalCell);
