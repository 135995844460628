
export default {
    project_name: 'ON FRIENDS',
    start: '시작하기',
    next: '다음',
    cancel: '취소',
    confirm: '확인',
    logout: '로그아웃',

    google: "구글",
    apple: "애플",
    naver: "네이버",
    kakao: "카카오",
    instagram: "인스타그램",
    year: 'Year',
    month: 'Month',
    day: 'Day',

    festival_types: [
        'Culture', 'Music', 'Food', 'Concert', "기타"
    ],

    login: {
        signin_with_kakao: "카카오로 로그인",
        signin_with_naver: "네이버로 로그인",
        signin_with_apple: "Apple로 로그인",
        signin_with_google: "Google로 로그인",
    },

    term_agree: {
        agree_terms: "약관동의",
        agree_to_all: "전체 동의",
        agree_to_use_rule: "이용약관 동의 (필수)",
        agree_to_privacy_policy: "개인정보 수집 및 이용 동의 (필수)",
        agree_to_market: "마케팅 이용 동의 (선택)",
        show: "보기",
        warning_msg: "약관에 동의하지 않으면 가입을 진행하실 수 없습니다.",
        use_rule: "이용약관",
        privacy_policy: "개인정보처리방침",
        market: "마케팅이용약관",
    },

    auth: {
        male: "남",
        female: "여",
        custom_info_set: "맞춤 정보 설정",
        enter_main_info: "기본 정보를 입력해주세요.",
        gender_select: "성별 선택",
        birthday: "생일",
        signup_path: "가입 경로",
        nickname: "닉네임",
        contact: "연락처",
        area_of_residence: "거주 지역",
        birthday_placeholder: "출생년도를 선택해주세요",
        signup_path_placeholder: "가입경로를 선택해주세요",
        signup_path_reasons: [
            '검색', '지인 권유'
        ],
        nickname_placeholder: "닉네임을 입력해주세요",
        contact_placeholder: "연락처를 입력해주세요",
        region_placeholder: "거주 지역을 선택해주세요",
        regions: [
            '서울', '경기/인천', '충청/대전/세종', '강원', '전북', '전남/광주',
        ],
        interest_regions: [
            '서울', '경기/인천', '충청/대전/세종', '강원', '전북', '전남/광주', '경북/대구','경남/부산/울산','제주','해외', "기타"
        ],
        etc: "기타",
        invalid_nickname: "닉네임에는 공백이 들어갈 수 없습니다.",
        invalid_phone: "연락처는 숫자만 입력해 주세요.",
        duplicate_phone: "연락처가 중복 됩니다.",
        duplicate_nickname: "닉네임이 중복 됩니다.",
        select_interest_area: "관심 지역을 선택해주세요.",
        interest_area_placeholder: "관심 지역을 입력해주세요",
        select_festival: "관심 페스티벌을 선택해주세요.",
        festival_placeholder: "관심 페스티벌을 입력해주세요",
        genres: [
            'POP', 'ROCK', 'EDM', 'HIPHOP', "HOUSE", "기타"
        ],
        select_genre: "좋아하는 음악장르는 무엇인가요?",
        genre_placeholder: "관심 음악 장르를 입력해주세요",
        signup_success: "회원 가입이 완료 되었습니다, 홈 화면으로 이동합니다.",
    },
    home: {
        about_friends: "온프렌즈가 궁금하세요?",
        on_friends: "온프렌즈란?",
        festival: "페스티벌",
        event: "이벤트",
        inquiry: "고객문의",
        banner_tip: "글로벌 페스티벌 버티컬 플랫폼",
        banner_tip2: "근처의 사람들과 모임을 개설하고, 내 친구의 위치를 확인해보세요.",
        banner_tip3: "온프렌즈",
        banner_tip4: "가 도와줄게요!",
        festival_desc: "국내에서 열리는 핫한 페스티벌에 대한 정보를 볼수 있습니다.",
        view_all: "전체보기",
        event_desc: "온프렌즈에서 열리는 이벤트를 확인하세요!",
        plus: "적립형",
        minus: "차감형",
        plus_type: "적립형",
        minus_type: "차감형",
        download_now: "지금 바로 다운로드하세요!",
        download_tip: "온프렌즈 어플을 다운받고 전국의 페스티벌을 만나보세요!\n다양한 페스티벌을 한눈에 확인하고 \n함께 즐길 친구들을 만나보세요~!",
    },
    right_bar: {
        go_to: "바로가기",
        login: "로그인",
        sir: "님",
        menu_items: ["온프렌즈란?", "페스티벌", "이벤트", "고객문의", "공지사항"],
        logout: "로그아웃",
        logout_desc: "로그아웃 하시겠습니까?"
    },
    footer: {
        business_info: "(주) 페스티벌온   |   강원특별자치도 춘천시 둥지길 4, 3층(효자동)\n사업자등록번호 : 733-81-02640   |   통신판매업 신고번호 : 2022-강원춘천-0399\n대중문화 예술 기획업 : 제2022-2호(강원도)   |   대표자 : 김재한 개인정보보호 책임자 : 이희진",
        contact_info: "문의     T. 1644-4401   |   F. 070-7966-4542   |   E. festivalon@festivalon.net",
        copyright_info: "Copyright ⓒ 2023 All Rights Reserved by (주) 페스티벌온"
    },
    about: {
        tip1: "언제, 어디서나 페스티벌을 즐기는\n사람들의 필수 플랫폼",
        tip2: "온프렌즈만의 3 Points",
        step1_title: "1 사용자 위치 기반의 모임 개설 및 커뮤니티",
        step1_desc: "내 위치를 기반으로 모임을 개설하고 온프렌즈의 \n커뮤니티 기능으로 다양한 사람들과\n소식을 공유하고 즐겨보세요.",
        step2_title: "2 페스티벌 사이트맵의 친구 위치 확인 기능",
        step2_desc: "온프렌즈만의 특허 기술로 행사 주최사가 제공한\n 페스티벌 사이트맵에서\n친구의 위치를 확인할 수 있습니다.",
        step3_title: "3 다국어 번역 기능으로 외국인 친구 만들기",
        step3_desc: "영어, 일본어 등 다국어 번역 기능을 제공함으로써\n페스티벌에서 만난 외국인들과 소통할 수 있습니다.",
        screenshot1_title: "페스티벌러들을 \n위한 모임 개설",
        screenshot1_desc: "사용자 위치를 기반으로 직접 모임을 \n개설하고 페스티벌 메이트를 찾을 수 있습니다.",
        screenshot2_title: "페스티벌 커뮤니티",
        screenshot2_desc: "SNS 기반의 커뮤니티 기능으로 페스티벌 소식, 정보, 후기 공유가 가능합니다",
        screenshot3_title: "페스티벌 라이브맵",
        screenshot3_desc: "페스티벌 안에서 길을 잃었다면?\n실제 지도를 바탕으로 실시간 친구 위치를 파악해보세요.",
        screenshot4_title: "페스티벌 사이트맵",
        screenshot4_desc: "매번 페스티벌 지도를 꺼내보기 힘들다면?\n행사 주최사 지도 위에서 친구의 위치를 파악해보세요. ",
        screenshot5_title: "다국어 통번역기",
        screenshot5_desc: "페스티벌에서 외국인과 친해지고 싶다면?\n영어, 일본어 등 다국어 통번역기\n 기능으로 소통해보세요",
    },
    festival: {
        music: "Music",
        culture: "Culture",
        food: "Food",
        concert: "Concert",
        etc: "Etc",
        view_all: "전체보기",
        festival_info: "페스티벌 정보",
        festival_info_desc: "전국에서 열리는 페스티벌을 한눈에 확인하세요",
        go_to_login: "좋아요 하기 위해서 로그인 페이지로 이동합니다."
    },
    event: {
        event: "이벤트",
        even_desc: "전국에서 열리는 페스티벌을 한눈에 확인하세요"
    },
    festival_detail: {
        md_pick: "MD PICK",
        schedule: "일정",
        location: "장소",
        related_events: "관련 이벤트",
        festival_info: "페스티벌 소개",
        time_table: "타임테이블",
        lineup: "라인업",
        schedule_add_success: "This is added in schedule list.",
        share_text: "지금 온프렌즈 앱을 다운받고 \n모든 기능을 사용해보세요!",
        on: "ON"
    },
    notice: {
        notice: "공지사항",
        notice_desc: "온프렌즈의 주요 공지사항을 알려드립니다."
    },
    inquiry: {
        customer_inquiry: "고객문의",
        inquiry_desc: "문의사항을 남겨주시면 회신드리겠습니다.",
        inquiry_type: "문의유형",
        inquiry_type_placeholder: "선택해주세요",
        manager_name: "담당자명",
        contract: "연락처",
        email_address: "이메일 주소",
        inquiry_content: "문의내용 입력",
        inquiry_content_placeholder: "문의하실 내용을 입력해 주세요.",
        required: "*",
        inquiry: "문의하기",
        inquiry_success: "문의가 올바르게 전송되었습니다.",
        inquiry_types: ["일반 문의", "제휴 문의"],
        select_inquiry_type: "문의 유형을 선택해주세요.",
        enter_phone: "연락처를 입력해주세요.",
        enter_email: "이메일 주소를 입력해주세요.",
        enter_manager_name: "담당자명을 입력해주세요.",
    }
}
