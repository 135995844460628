import styles from './FestivalDetail.module.scss'
import BasePage from '@/pages/BasePage'
import { useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames/bind'
import Header from '@/components/header/Header'
import Icon from '@/components/Icon/Icon'
import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import actions from '../../redux/actions'
import { connect } from 'react-redux'
import Footer from '@/components/footer/Footer'
import strings from '@/lang/strings'
import { getDateString, getDistanceFromLatLonInKm, sendRequest } from '@/common/common'
import btnCalendar from '@/assets/images/test_schedule@3x.png'
import btnGps from '@/assets/images/test_location@3x.png'
import imgDefaultProfile from '@/assets/images/img_default_profile@2x.png'
import Carousel from '@/components/carousel/Carousel'
import EventCell from '@/components/home/EventCell'

const cx = classNames.bind(styles)

const FestivalDetail = ({user: {user, userLocation}, festival: {festival}, appActions, festivalActions, classActions, eventActions}) => {
  const navigate = useNavigate()
  const params = useParams()

  const [isLikeLocal, setIsLikeLocal] = useState(festival?.isLike)
  const [likeCountLocal, setLikeCountLocal] = useState(festival?.likeCount)

  useEffect(() => {
    getDetail()
  }, [])

  useEffect(() => {
    setLikeCountLocal(festival?.likeCount)
    setIsLikeLocal(festival?.isLike)
  }, [festival])

  const getDetail = () => {
    sendRequest(`festival/festival/${params.id}/`, 'GET', null,  data => {
      festivalActions.setFestival(data)
    })
  }

  const handleShare = (item) => {
    appActions?.showShareModal()
  };

  const handleAddSchedule = (festival) => {
    sendRequest('log/schedule/', 'POST', {
      startDateTime: festival?.startDateTime,
      endDateTime: festival?.endDateTime,
      content: festival?.title,
      festival: festival?.id
    }, data => {
      appActions?.showAlertModal({
        text: strings.festival_detail.schedule_add_success
      })
    }, (data, code) => {
      appActions?.showAlertModal({
        text: data?.msg
      })
    })
  }

  return (
    <>
      <BasePage id={'festival_detail'} className={cx(styles.root)} pageContent={
        <>
          <Header
            styles={{position: 'absolute'}}
            hasBack
            backIcon={'icon_back_white'}
            hasBackground={false}
            rightComp={
              <div style={{display: 'flex'}}>
                <div className={"w-30 h-40 center"} onClick={() => navigate('/search')}>
                  <Icon variant="search" className={"w-16 h-16"} />
                </div>
                <div className={"w-30 h-40 center mr-5"} onClick={() => appActions.showRightModal()}>
                  <Icon variant="icon_option" className={"w-16 h-16"} />
                </div>
              </div>
            }
          />
          <div className={styles.image_swiper}>
            {
              festival.images?.length &&
              <Carousel
                data={festival.images}
                swiperProps={{
                  direction: 'horizontal',
                  autoplay: true,
                }}
                RenderItem={({item, index}) => {
                  return (
                    <img src={item} className={styles.image} onClick={() => {
                      // navigate('Notice', {notice: item})
                    }} />
                  )
                }}/>
            }
          </div>
          <div className={"mh-12 mt-40"}>
            <div className={cx(styles.badge_wrapper)}>
              { !festival?.isMDPick &&
              <div className={cx(styles.badge, 'selected')}>{strings.festival_detail.md_pick}</div>
              }
              <div className={cx(styles.badge, "ml-4")}>{festival.festivalType}</div>
            </div>
            <div className={styles.title_wrapper}>
              <div className={styles.title}>{festival?.title}</div>
              <div className={"pv-10"} onClick={() => handleShare(festival)}>
                <Icon variant={"icon_share"} className={"w-14 h-14"} />
              </div>
            </div>
            <div className={styles.info_border_container}>
              <div>
                <div className={styles.info_title_wrapper}>
                  <Icon variant={"icon_schedule"} className={"w-12 h-12"} />
                  <div className={"ml-4"}>{strings.festival_detail.schedule}</div>
                </div>
                <div className={styles.info_date}>{getDateString(new Date(festival?.startDateTime))} ~ {getDateString(new Date(festival?.endDateTime))}</div>
              </div>
              <img src={btnCalendar} className={"w-20 h-20"} onClick={() => handleShare(festival)} />
            </div>
            <div className={styles.info_border_container}>
              <div>
                <div className={styles.info_title_wrapper}>
                  <Icon variant={"icon_location"} className={"w-12 h-12"} />
                  <div className={"ml-4"}>{strings.festival_detail.location}</div>
                </div>
                <div className={styles.info_date}>{festival?.addressTitle}</div>
                <div className={styles.info_desc}>{festival?.address}</div>
              </div>
              <img src={btnGps} className={cx(styles.icon_gps, "w-24 h-24")} onClick={() => handleShare(festival)} />
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.section_title}>{strings.festival_detail.related_events}</div>
            <div className={styles.event_wrapper}>
              { festival?.events && festival?.events?.map((item, index) => {
                return (
                  <EventCell
                    key={index}
                    title={item.title}
                    date={getDateString(new Date(item.startDateTime)) + ' ~ ' + getDateString(new Date(item.endDateTime))}
                    location={item?.address}
                    distance={getDistanceFromLatLonInKm(item?.lat, item?.lon, userLocation.latitude, userLocation.longitude) + 'km'}
                    item={item}
                    showInfoOuter={true}
                    pointType={item?.pointType}
                    aspectRatio={2.08}
                    width={213}
                    style={{marginRight: '8px'}} />
              )})}
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.section_title}>{strings.festival_detail.festival_info}</div>
            <div className={styles.section_desc}>{festival?.content}</div>
          </div>
          <div className={styles.section}>
            <div className={styles.section_title}>{strings.festival_detail.time_table}</div>
            <img src={festival?.timeLine} className={styles.timeline}/>
          </div>
            <div className={cx(styles.section, "mb-45")}>
              <div className={styles.section_title}>{strings.festival_detail.lineup}</div>
              <div className={styles.avatar_wrapper}>
                {festival?.celebrities?.map((item, index) => {
                  return (
                    <div key={index} className={cx(styles.avatar_item, "mr-12")} onClick={() => {
                      festivalActions.setPerson(item)
                      navigate('/person')
                    }}>
                      <img
                        className={styles.avatar_image}
                        src={item.image ? item.image : imgDefaultProfile}
                      />
                      <div className={styles.avatar_title}>{item.title}</div>
                    </div>
                  )
                })}
              </div>
            </div>
          <Footer backgroundColor={"#f3f3f3"} />
        </>
      } />
    </>
  )
}

const mapStateToProps = state => ({
  appState: state.appState,
  user: state.user,
  festival: state.festival
});

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators(actions.appActions, dispatch),
  userActions: bindActionCreators(actions.userActions, dispatch),
  festivalActions: bindActionCreators(actions.festivalActions, dispatch),
  eventActions: bindActionCreators(actions.eventActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FestivalDetail);
