import { Route, Routes } from 'react-router-dom'
import Login from './pages/auth/Login'
import TermAgree from '@/pages/auth/TermAgree'
import AuthInfoForm from '@/pages/auth/AuthInfoForm'
import AuthLocationForm from '@/pages/auth/AuthLocationForm'
import AuthFestivalForm from '@/pages/auth/AuthFestivalForm'
import AuthGenreForm from '@/pages/auth/AuthGenreForm'
import Home from '@/pages/home/Home'
import Search from '@/pages/home/Search'
import About from '@/pages/home/About'
import Festival from '@/pages/festival/Festival'
import Event from '@/pages/event/Event'
import FestivalDetail from '@/pages/festival/FestivalDetail'
import PersonDetail from '@/pages/festival/PersonDetail'
import Notice from '@/pages/home/Notice'
import Inquiry from '@/pages/home/Inquiry'
import FestivalByType from '@/pages/festival/FestivalByType'

const Router = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/term-agree" element={<TermAgree />} />
        <Route path="/auth-info-form" element={<AuthInfoForm />} />
        <Route path="/auth-location-form" element={<AuthLocationForm />} />
        <Route path="/auth-festival-form" element={<AuthFestivalForm />} />
        <Route path="/auth-genre-form" element={<AuthGenreForm />} />
        <Route path="/home" element={<Home />} />
        <Route path="/search" element={<Search />} />
        <Route path="/about" element={<About />} />
        <Route path="/festival" element={<Festival />} />
        <Route path="/Festival-by-type/:type" element={<FestivalByType />} />
        <Route path="/event" element={<Event />} />
        <Route path="/festival/:id" element={<FestivalDetail />} />
        <Route path="/notice/:id" element={<Notice />} />
        <Route path="/inquiry" element={<Inquiry />} />
        <Route path="/person" element={<PersonDetail />} />
      </Routes>
    </>
  )
}

export default Router
