
export default {
    project_name: 'ON FRIENDS',
    start: 'Start',
    next: 'Next',
    cancel: 'Cancel',
    confirm: 'Confirm',
    logout: 'Logout',

    google: "Google",
    apple: "Apple",
    naver: "Naver",
    kakao: "Kakao",
    instagram: "Instagram",    
    year: 'Year',
    month: 'Month',
    day: 'Day',

    festival_types: [
        'Music', 'Culture', 'Food', 'Concert', "Etc"
    ],

    login: {
        signin_with_kakao: "Sign in with Kakao",
        signin_with_naver: "Sign in with Naver",
        signin_with_apple: "Sign in with Apple",
        signin_with_google: "Sign in with Google",
    },

    term_agree: {
        agree_terms: "Agree To Terms",
        agree_to_all: "Agree to all",
        agree_to_use_rule: "Agree to use rule (Required)",
        agree_to_privacy_policy: "Agree to privacy policy (Required)",
        agree_to_market: "Agree to market (Optional)",
        show: "Show",
        warning_msg: "If you do not agree, you cannot proceed with registration.",
        use_rule: "Use rule",
        privacy_policy: "Privacy policy",
        market: "Market",
    },

    auth: {
        male: "Male",
        female: "Female",
        custom_info_set: "Custom Info Set",
        enter_main_info: "Please enter main info.",
        gender_select: "Gender select",
        birthday: "Birthday",
        signup_path: "Signup path",
        nickname: "Nickname",
        contact: "Contact",
        area_of_residence: "Area of residence",
        birthday_placeholder: "Please select birth year",
        signup_path_placeholder: "Please select signup path",
        signup_path_reasons: [
            'Search', 'Friend recommend'
        ],
        nickname_placeholder: "Please enter nickname",
        contact_placeholder: "Please enter contact",
        region_placeholder: "Please select area of residence",
        regions: [
            'Location1', 'Location2', 'Location3', 'Location4', 'Location5', 'Location6',
        ],
        interest_regions: [
            'Location1', 'Location2', 'Location3', 'Location4', 'Location5', 'Location6', 'Location7','Location8','Location9','Overseas', "Etc"
        ],
        etc: "Etc",
        invalid_nickname: "The nickname cannot contain a space",
        invalid_phone: "The phone must contain numbers only.",
        duplicate_phone: "Duplicated phone.",
        duplicate_nickname: "Duplicated nickname.",
        select_interest_area: "Please select interest area.",
        interest_area_placeholder: "Please enter interest area.",
        select_festival: "Please select a festival of interest.",
        festival_placeholder: "Please enter the festival of interest.",
        genres: [
            'POP', 'ROCK', 'EDM', 'HIPHOP', "HOUSE", "Etc"
        ],
        select_genre: "What is your favorite music genre?",
        genre_placeholder: "Please enter the music genre you are interested in.",
        signup_success: "Signup done, Go to home",
    },
    home: {
        about_friends: "Curious about On Friends?",
        on_friends: "On Friends?",
        festival: "Festival",
        event: "Event",
        inquiry: "Inquiry",
        banner_tip: "Global festival vertical platform",
        banner_tip2: "Create a meeting with people nearby and check the locations of friends.",
        banner_tip3: "On Friends ",
        banner_tip4: "will help you",
        festival_desc: "You can see information about hot festivals held in Domestic",
        view_all: "View all",
        event_desc: "Check out the events taking place on On Friends!",
        plus: "Plus",
        minus: "Minus",
        plus_type: "Plus type",
        minus_type: "Minus type",
        download_now: "Download now!",
        download_tip: "Download the App and experience festivals!\nCheck out various festivals at a glance\nand meet friends to enjoy together!",
    },
    right_bar: {
        go_to: "Go to",
        login: "Login",
        sir: "Sir",
        menu_items: ["OnFriends?", "Festival", "Event", "Inquiry", "Notice"],
        logout: "Logout",
        logout_desc: "Do you want to logout"
    },
    footer: {
        business_info: "(Co) FestivalOn   |   AAAA BBBB CCCC 4-3(123)\nBusiness Num : 733-81-02640   |   Report number : 2022-0399\nPopular art : 2022-2(123)   |   Seo : Kelvin\nMaster : jerry",
        contact_info: "Inquiry     T. 000-1111-2222   |   F.000-1111-2222   |   E.festival@festival.net",
        copyright_info: "Copyright @ 2023 All Rights Reserved by (Co) Festival On"
    },
    about: {
        tip1: "An essential platform for people who enjoy festivals anytime, anywhere",
        tip2: "On Friends only 3 P",
        step1_title: "1 Meetings and communities based on user location",
        step1_desc: "Create a meeting based on your location and enjoy and share news with a variety of people using OnFriends` community feature",
        step2_title: "2 Friend location check function on",
        step2_desc: "With OnFriends` patented technology, you can check your friends` locations on the festival site map provided by the event organizer",
        step3_title: "3 Make foreign friends with translate",
        step3_desc: "By providing multi-language translation functions such as English and Japanese, you can communicate with foreigners you meet at the festival",
        screenshot1_title: "Open of a meeting for festival goers",
        screenshot1_desc: "You can create your own meetings and find festival mates based on your location.",
        screenshot2_title: "Festival community",
        screenshot2_desc: "SNS based community functions allow you to share festival news, information, and reviews",
        screenshot3_title: "Festival live map",
        screenshot3_desc: "What if you get lost at the festival? Find out where your friends are in realtime based on a real map",
        screenshot4_title: "Festival site map",
        screenshot4_desc: "What if it`s hard to take out every time? Find out your friends` locations on the event organizer`s map",
        screenshot5_title: "Multi interpreter",
        screenshot5_desc: "Want to friends with foreigners? Communicate using the multilingual interpretation and translator function, including English and Japanese",
    },
    festival: {
        music: "Music",
        culture: "Culture",
        food: "Food",
        concert: "Concert",
        etc: "Etc",
        view_all: "View all",
        festival_info: "Festival information",
        festival_info_desc: "Check out festivals taking place across the country",
        go_to_login: "The app goes to login page for liking"
    },
    event: {
        event: "Event",
        even_desc: "Participate in various events at On Friends"
    },
    festival_detail: {
        md_pick: "MD PICK",
        schedule: "Schedule",
        location: "Location",
        related_events: "Related events",
        festival_info: "Festival introduction",
        time_table: "Time table",
        lineup: "Lineup",
        schedule_add_success: "This is added in schedule list.",
        share_text: "Download the Onfriends app now and try out all the features.",
        on: "ON"
    },
    notice: {
        notice: "Notice",
        notice_desc: "Here are important notices from On Friends"
    },
    inquiry: {
        customer_inquiry: "Customer inquiry",
        inquiry_desc: "Please leave your inquiry and we will reply.",
        inquiry_type: "Inquiry type",
        inquiry_type_placeholder: "Please select",
        manager_name: "Manager name",
        contract: "Contract",
        email_address: "Email address",
        inquiry_content: "Inquiry content",
        inquiry_content_placeholder: "Please enter your inquiry",
        required: "*",
        inquiry: "Inquiry",
        inquiry_success: "Your inquiry was sent correctly.",
        inquiry_types: ["Common inquiry", "Affiliated inquiry"],
        select_inquiry_type: "Please select inquiry type",
        enter_phone: "Please enter your contract",
        enter_email: "Please enter your email address",
        enter_manager_name: "Please enter manager name",
    },
}
